import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { ReferenceDataService } from '../../services/referencedata/reference-data.service';
import { RoleService } from '../../services/role/role.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})

export class UserProfileComponent implements OnInit {

    public editHistorys: any = [{ EventDateTime: "12/05/2023", EventType: "Created", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "01/05/2024", EventType: "Modified", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "Deleted", EventUser: "Roy.Munson", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "UnDeleted", EventUser: "Joe.Banks", EventCommentary: "This should not have been deleted.  Reactivating" }];
    public userRoleNames: any = [];
    public allRoles: any = [];
    public creditors: any = [];

    public constructor(public appService: AppService,
                       private roleService: RoleService,
                       private referenceDataService: ReferenceDataService) { }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        this.userRoleNames = this.appService.CurrentSession.AuthenticatedUser.User.Roles;

        forkJoin(this.referenceDataService.getCreditors(),
                 this.roleService.search({ RoleName: "" })).subscribe(([creditors, roles]) => {

            var allowedCreditors = this.appService.CurrentSession.AuthenticatedUser.User.Claims.filter((i: any) => i.Type.toLowerCase() == "creditorid").map((i: any) => i.Value);
            this.creditors = creditors.filter((i: any) => allowedCreditors.indexOf(i.CreditorId) > - 1);
            this.allRoles = roles;

            this.appService.IsAppBusy = false;
        });
    }

    public editRole = (roleName: string): void => {

        if (this.userRoleNames.filter((i: string) => i.toUpperCase() == "ADMIN") || this.userRoleNames.filter((i: string) => i.toUpperCase() == "MASTER")) {

            const roleIds = this.allRoles.filter((i: any) => i.Name.toUpperCase() == roleName.toUpperCase());

            if (roleIds != null && roleIds.length > 0) {

                this.appService.navigateTo('/role/' + roleIds[0].Id);
            }
        }
    }

    public editUser = (): void => {

        this.appService.navigateTo('/user/' + this.appService.CurrentSession.AuthenticatedUser.User.Id);
    }
}
