import { Component, OnInit, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { ContactService } from '../../services/contact/contact.service';
import { ReferenceDataService } from '../../services/referencedata/reference-data.service';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

@Component({
    selector: 'app-contact-search',
    templateUrl: './contact-search.component.html',
    styleUrls: ['./contact-search.component.css']
})

export class ContactSearchComponent implements OnInit {

    public creditorsFormControl: FormControl = new FormControl();
    public statusesFormControl: FormControl = new FormControl();
    public creditorsList: any = [];
    public searchParameters: any = null;
    public resultsMatTableDataSource: any = [];
    public displayedColumns: string[] = ['Avatar', 'CreditorId', 'IntegrationLinkId', 'FirstName', 'LastName'];
    public resultsClass: string = 'display-none';

    @ViewChild(MatSort) sort: any;
    @ViewChild(MatPaginator) paginator: any;

    public constructor(public appService: AppService,
                       public contactService: ContactService,
                       public referenceDataService: ReferenceDataService) {
    }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        forkJoin(this.contactService.getDefaultSearchParameters(),
                 this.referenceDataService.getCreditors()).subscribe(([searchParameters,
                                                                       creditors]) => {

            var allowedCreditors = this.appService.CurrentSession.AuthenticatedUser.User.Claims.filter((i: any) => i.Type.toLowerCase() == "creditorid").map((i: any) => i.Value);
            this.creditorsList = creditors.filter((i: any) => allowedCreditors.indexOf(i.CreditorId) > - 1);
            this.searchParameters = searchParameters;

            this.appService.IsAppBusy = false;
        });
    }

    public search = () => {

        if (this.searchParameters.IntegrationLinkId == '') {

            this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Missing Input", "You didn't enter an integration link id.  Enter an integration link id or part of an integration link id.", "GOTCHA", "");

        } else {

            this.appService.IsAppBusy = true;
            this.contactService.search(this.searchParameters).subscribe((records: any) => {

                if (records != '') {

                    this.appService.IsAppBusy = false;

                    if (records.length == 1 && records[0].CreditorId == null) { // YUCK: need a better way to detect no results

                        this.resultsClass = 'display-none';
                        this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Empty Search Results", "No data was returned for the criteria.  Check the creditor id, statuses etc", "OK", "");

                    } else {

                        this.resultsClass = 'display-block';
                        this.resultsMatTableDataSource = new MatTableDataSource(records);
                        this.resultsMatTableDataSource.sort = this.sort;
                        this.resultsMatTableDataSource.paginator = this.paginator;
                    }
                }
            });
        }
    }

    public edit = (item: any) => {

        try {

            const creditorId = item.CreditorId.split('creditor/')[1];

            const route = 'contactjson/' + creditorId + '/' + item.IntegrationLinkId;

            this.appService.navigateTo(route);

        } catch (e) {

            this.appService.ShowModalDialog(ModalDialogSeverity.Error, "Error Navigating to Item", JSON.stringify(e), "OK :(", "");
        }
    }
}
