<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="mat-card-header-text">
            <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                function
            </div>
        </div>
        <mat-card-title>Search Managed Functions</mat-card-title>
        <mat-card-subtitle>Managed functions are Azure functions that can be configured and controlled from this console</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form (keyup.enter)="search()">
            <div>
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Name</mat-label>
                    <span matPrefix style="color:dimgrey;">
                        <span class="material-symbols-outlined" matTooltip="Leading search wildcard" style="font-size:10pt;">
                            star
                        </span>
                    </span>
                    <input matInput #name size="1" (keyup)="appService.syncWildcardSuffixPosition($event, false)" (focus)="appService.syncWildcardSuffixPosition($event, false)" (blur)="appService.syncWildcardSuffixPosition($event, true)" class="input-prefix-suffix" maxlength="256" [(ngModel)]="searchParameters.Name" width="220" name="Name" />
                    <span matSuffix class="wildcard-suffix" style="color:dimgrey;">
                        <span class="material-symbols-outlined" matTooltip="Trailing search wildcard" style="font-size:10pt;position:relative;left:0px;">
                            star
                        </span>
                    </span>
                </mat-form-field>
            </div>
            <div class="{{advancedSearchClass}}">
                (Advanced Search Fields)
            </div>
            <button mat-button (click)="search()">
                <span class="material-symbols-outlined">
                    search
                </span>
                SEARCH
            </button>
            <button mat-button (click)="toggleAdvancedSearch()">
                <span class="material-symbols-outlined">
                    tune
                </span>
                ADVANCED
            </button>
            <button mat-button (click)="create()">
                <span class="material-symbols-outlined">
                    add
                </span>
                ADD NEW
            </button>
        </form>
    </mat-card-content>
    <mat-card-footer>
    </mat-card-footer>
</mat-card>
<br />
<mat-card class="{{resultsClass}}">
    <mat-card-title>
        Results
    </mat-card-title>
    <mat-card-content>
        <table mat-table matSort [dataSource]="resultsMatTableDataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Avatar">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)">
                    <span class="material-symbols-outlined" style="font-size: 18pt;">
                        function
                    </span>
                    <span *ngIf="item.IsDisabled" class="material-symbols-outlined timer_inactive">
                        timer_off
                    </span>
                    <span *ngIf="!item.IsDisabled" class="material-symbols-outlined timer_active">
                        timer
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.Name}} </td>
            </ng-container>
            <ng-container matColumnDef="Type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.ManagedFunctionType}} </td>
            </ng-container>
            <ng-container matColumnDef="IsDisabled">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IsDisabled </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.IsDisabled}} </td>
            </ng-container>
            <ng-container matColumnDef="CronTriggerExpression">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CronTriggerExpression </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.CronTriggerExpression}} </td>
            </ng-container>
            <ng-container matColumnDef="LastStartDateTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> LastStartDateTime </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)" matTooltip="{{item.LastStartDateTime | dateUtcToLocal:randomNumber}}"> {{item.LastStartDateTime | dateAgo:randomNumber}} </td>
            </ng-container>
            <ng-container matColumnDef="LastFinishDateTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> LastFinishDateTime </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)" matTooltip="{{item.LastFinishDateTime | dateUtcToLocal:randomNumber}}"> {{item.LastFinishDateTime | dateAgo:randomNumber}} </td>
            </ng-container>
            <ng-container matColumnDef="Duration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.Duration}} </td>
            </ng-container>
            <ng-container matColumnDef="IsRunning">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IsRunning </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.IsRunning}} </td>
            </ng-container>
            <ng-container matColumnDef="NextRunDateTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> NextRunDateTime </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"matTooltip="{{item.NextRunDateTime | dateUtcToLocal:randomNumber}}"> {{item.NextRunDateTime | dateAgo:randomNumber}} </td>
            </ng-container>
            <ng-container matColumnDef="IsDue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IsDue </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.IsDue}} </td>
            </ng-container>
            <ng-container matColumnDef="OpenModal">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item" (click)="delete(item)">
                    <span class="material-symbols-outlined" style="font-size: 18pt;">
                        delete_forever
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
    <mat-card-footer>
        <mat-paginator *ngIf="resultsRecordCount > 0" [pageSizeOptions]="[20, 50, 100]" aria-label="Select page of items"></mat-paginator>
    </mat-card-footer>
</mat-card>

