import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { MatInputModule } from '@angular/material/input';
import { FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';

@Component({
    selector: 'app-invoice-basic-test',
    templateUrl: './invoice-basic-test.component.html',
    styleUrls: ['./invoice-basic-test.component.css']
})

export class InvoiceBasicTestComponent implements OnInit {

    public searchModel: any = {
        AccountInvoiceId: '',
        ResultObject: null,
        ResultJson: ''
    }

    public constructor(public appService: AppService,
                       public invoiceService: InvoiceService,
                       private route: ActivatedRoute) {

        const parameters = route.params;
        let accountInvoiceId = '';

        if (parameters != null) {

            parameters.subscribe(p => {

                accountInvoiceId = 'creditor/' + p['creditorId'] + '/accountinvoice/' + p['integrationLinkId'];
                this.searchModel.AccountInvoiceId = accountInvoiceId;

                this.getSqlAccountInvoice();
            });
        }

        this.searchModel.AccountInvoiceId = accountInvoiceId;
    }

    public ngOnInit(): void { }

    public getSqlAccountInvoice = () => {

        if (this.searchModel.AccountInvoiceId == '') {

            this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Missing Input", "You didn't enter an account invoice id.  Enter an account invoice id in thre format creditor/{creditorid}/accountinvoice/{integrationlinkid}.  So for example creditor/1/accountinvoice/abc123.", "GOTCHA", "");

        } else {

            this.appService.IsAppBusy = true;

            this.invoiceService.getSqlAccountInvoice(this.searchModel.AccountInvoiceId).subscribe((r: any) => {

                if (r != '') {

                    this.searchModel.ResultObject = r;
                    this.searchModel.ResultJson = JSON.stringify(r, null, 3);
                    this.appService.IsAppBusy = false;
                }
            });
        }
    }

    public saveSqlAccountInvoice = () => {

        this.appService.IsAppBusy = true;

        this.invoiceService.saveSqlAccountInvoice(this.searchModel.ResultJson).subscribe((r: any) => {

            if (r != '') {

                if (r instanceof HttpErrorResponse) {

                    this.appService.IsAppBusy = false;
                    this.appService.ShowModalDialog(ModalDialogSeverity.Error, "Error Saving Invoice", "There was an error saving the invoice.  Error has been logged.  AI has already determined the cause based on log details and ticket number for fix is 1234 with an anticipated fix window of 8 hours as of (current time here)", "HAVE A NICE DAY", "");

                } else {

                    this.searchModel.ResultObject = r;
                    this.searchModel.ResultJson = JSON.stringify(r, null, 3);
                    this.appService.IsAppBusy = false;
                }
            }
        });
    }

    public getSampleSqlQuery = () => {

        const sampleSqlQuery = this.invoiceService.getSampleSqlQuery(this.searchModel.AccountInvoiceId);

        this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Sample SQL Script (for current Invoice)", sampleSqlQuery, "AWESOME !", "");
    }
}
