<div style="padding: 4px;">
    <br />
    <mat-slide-toggle [(ngModel)]="appService.isNavigatorPinned" color="primary" matTooltip="Hide or pin navigation panel after each navigation">
    </mat-slide-toggle>
    <br />
    <br />
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" style="background-color:transparent;">
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <span (click)="appService.navigateTo(node.route)" class="active-label nav-item" style="vertical-align: middle">
                <span class="material-symbols-outlined" matTooltip="{{node.name}}" style="color:{{node.color}};" style="vertical-align: middle">
                    {{node.maticon}}
                </span>
            </span>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <span (click)="appService.navigateTo(node.route)" class="active-label nav-item" style="vertical-align: middle">
                <span class="material-symbols-outlined" matTooltip="{{node.name}}" style="color:{{node.color}};" style="vertical-align: middle">
                    {{node.maticon}}
                </span>
            </span>
        </mat-tree-node>
    </mat-tree>
</div>
