import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../../app.service';

@Injectable({
    providedIn: 'root'
})

export class NavigationNodeService {

    private _baseUrl: string = '';

    @Output() navigationUpdated = new EventEmitter<boolean>();

    public constructor(public appService: AppService, public http: HttpClient, @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public getById = (navigationNodeId: number): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'Navigation/GetById/' + navigationNodeId;
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getNewNode = (parentNavigationNodeId: number): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'Navigation/GetNewNode/' + parentNavigationNodeId;
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public save = (node: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Navigation/Save';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, node, options).subscribe((response: any) => {

            result.next(response);
            result.complete();
            this.navigationUpdated.emit(true);

        }, error => {

            result.next(error);
        });

        return result;
    }
}
