<div style="padding: 4px;">
    <br />
    <mat-slide-toggle [(ngModel)]="appService.isNavigatorPinned" color="primary">
        <span style="color:grey;font-weight:300;" matTooltip="Hide or pin navigation panel after each navigation">Pin Menu</span>
    </mat-slide-toggle>
    <br />
    <br />
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" style="background-color:transparent;">
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <span (click)="appService.navigateTo(node.route)" class="active-label nav-item" style="vertical-align: middle">
                <span class="material-symbols-outlined" style="color:{{node.color}};" style="vertical-align: middle">
                    {{node.maticon}}
                </span>
                <span class="nav-label" style="font-weight: bold;vertical-align: middle">
                    {{node.name}}
                </span>
            </span>
            <span *ngIf="isMasterMember" class="material-symbols-outlined node-management-button" matTooltip="Edit this node" (click)="edit(node)" style="font-size:14pt;">
                edit
            </span>
            <span *ngIf="isMasterMember" class="material-symbols-outlined node-management-button" matTooltip="Add new below" (click)="add(node)" style="font-size:14pt;">
                add
            </span>
            <mat-icon class="mat-icon-rtl-mirror" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <span (click)="appService.navigateTo(node.route)" class="active-label nav-item" style="vertical-align: middle">
                <span class="material-symbols-outlined" style="color:{{node.color}};" style="vertical-align: middle">
                    {{node.maticon}}
                </span>
                <span class="nav-label" style="font-weight: bold; vertical-align: middle">
                    {{node.name}}
                </span>
            </span>
            <span *ngIf="isMasterMember" class="material-symbols-outlined node-management-button" matTooltip="Edit this node" (click)="edit(node)" style="font-size:14pt;">
                edit
            </span>
            <span *ngIf="isMasterMember" class="material-symbols-outlined node-management-button" matTooltip="Add new below" (click)="add(node)" style="font-size:14pt;">
                add
            </span>
        </mat-tree-node>
    </mat-tree>
</div>
