import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { ReferenceDataService } from '../../services/referencedata/reference-data.service';
import { RoleService } from '../../services/role/role.service';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RoleEditComponent } from '../role-edit/role-edit.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-role-search',
    templateUrl: './role-search.component.html',
    styleUrls: ['./role-search.component.css']
})

export class RoleSearchComponent implements OnInit {

    public searchParameters: any = null;
    public results: any = null;
    public resultsMatTableDataSource: any = null;
    public displayedColumns: string[] = ['Avatar', 'RoleName', 'deleteRole'];
    public advancedSearchClass: string = 'display-none';
    public resultsClass: string = 'display-none';
    public resultsRecordCount: number = 0;

    @ViewChild(MatSort) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator) paginator: any;

    public constructor(public appService: AppService,
                       public roleService: RoleService,
                       public referenceDataService: ReferenceDataService,
                       public matDialog: MatDialog) {
    }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        forkJoin(this.roleService.getDefaultSearchParameters()).subscribe(([searchParameters]) => {

            this.searchParameters = searchParameters;
            this.appService.IsAppBusy = false;
        });
    }

    public search = () => {

        this.appService.IsAppBusy = true;

        this.roleService.search(this.searchParameters).subscribe((records: any) => {

            if (records != null) {

                this.appService.IsAppBusy = false;

                if (records.length == 0) {

                    this.results = [];
                    this.resultsClass = "display-none";
                    this.resultsRecordCount = 0;
                    this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Empty Search Results", "No data was returned for the criteria", "OK", "");

                } else {

                    this.resultsClass = "display-block";
                    this.results = records;
                    this.resultsRecordCount = records.length;

                    // sort does not like items with 'name' property.  do a quick reshape to allow sorting to function 
                    let reshapedRecords = this.results.map((item: { Id: number; Name: string, NormalizedName: string, ConcurrencyStamp: string }) => {
                        return ({ Id: item.Id, RoleName: item.Name, NormalizedName: item.NormalizedName, ConcurrencyStamp: item.ConcurrencyStamp })
                    });

                    this.resultsMatTableDataSource = new MatTableDataSource(reshapedRecords);
                    this.resultsMatTableDataSource.sort = this.sort;
                    this.resultsMatTableDataSource.paginator = this.paginator;
                }
            }
        });
    }

    public edit = (item: any, isModal: boolean = false) => {

        try {

            const route = 'role/' + item.Id;

            if (isModal) {

                const dialogConfig = new MatDialogConfig();

                dialogConfig.disableClose = false;
                dialogConfig.autoFocus = true;
                dialogConfig.width = "90%";
                dialogConfig.height = "90%";
                dialogConfig.data = item;

                this.matDialog.open(RoleEditComponent, dialogConfig);

            } else {

                this.appService.navigateTo(route);
            }

        } catch (e) {

            this.appService.ShowModalDialog(ModalDialogSeverity.Error, "Error Navigating to Item", "Unable to navigate to role " + item.Id, "OK :(", "");
        }
    }

    public create = (): void => {

        let route = 'role/0';

        this.appService.navigateTo(route);
    }

    public delete = (role: any): void => {

        let proceedResponse = "PROCEED TO DELETE";

        this.appService.ShowModalDialog(ModalDialogSeverity.Warning,
                                        "Confirm Deletion",
                                        "Are you sure you want to delete role " + role.Name + ".  If you are 100% sure, click PROCEEED TO DELETE, otherwise CANCEL",
                                        proceedResponse, 
                                        "CANCEL").subscribe((response) => {

            if (response == proceedResponse) {

                this.appService.IsAppBusy = true;

                this.roleService.delete(role.Id).subscribe((r: any) => {

                    if (r != '') {

                        this.appService.IsAppBusy = false;

                        if (r instanceof HttpErrorResponse) {

                            this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                        } else {

                            // refresh the results grid
                            const itemIndex = this.results.map((i: { Name: any; }) => i.Name).indexOf(role.RoleName);

                            if (itemIndex > -1) {

                                this.results.splice(itemIndex, 1);
                            }
                            
                            let reshapedRecords = this.results.map((item: { Id: number; Name: string, NormalizedName: string, ConcurrencyStamp: string }) => {
                                return ({ Id: item.Id, RoleName: item.Name, NormalizedName: item.NormalizedName, ConcurrencyStamp: item.ConcurrencyStamp })
                            });

                            this.resultsMatTableDataSource = new MatTableDataSource(reshapedRecords);
                            this.resultsMatTableDataSource.sort = this.sort;
                            this.resultsMatTableDataSource.paginator = this.paginator;
                        }
                    }
                });
            }
        });
    }
}
