import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class RoleService {

    private _baseUrl: string = '';

    public constructor(public appService: AppService,
                       public http: HttpClient,
                       @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public getDefaultSearchParameters = (): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'role/GetDefaultSearchParameters';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public search = (userSearchParameters: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Role/Search';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, userSearchParameters, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getById = (roleId: string): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Role/GetById/' + roleId;
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public save = (role: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Role/Save';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, role, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getEmptyRole = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Role/GetEmptyRole';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public delete = (roleId: string): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Role/Delete/' + roleId;
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }
}
