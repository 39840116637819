<div class="px-3">
  <div class="text-lg">
    Notification Settings
  </div>

  <div class="mt-6">

    <p-floatLabel>
      <p-autoComplete 
         styleClass="w-5"
         [(ngModel)]="selectedCreditor" 
         [suggestions]="filteredCreditors"  
         (completeMethod)="filterCreditors($event)" 
         (onSelect)="onSelectCreditor($event)"
         [dropdown]="true" 
         optionLabel="CompanyName"
         placeholder="Select Creditor"
         id="creditors"
         >
         <ng-template let-creditor pTemplate="item">
          <div class="flex align-items-center justify-content-between flex-1">
            <div>{{creditor.CompanyName}}</div>

            <div>{{creditor.CreditorId}}</div>
          </div>
      </ng-template>
   </p-autoComplete>
      <label for="creditors">Creditor</label>
   </p-floatLabel>
    
   <div class="w-5 mt-6">
    <p-floatLabel>
      <p-autoComplete 
         styleClass="w-full"
         [(ngModel)]="sms" 
         [suggestions]="filteredSmsPlatformTypeList"  
         (completeMethod)="filterSmsPlatformType($event)" 
         [dropdown]="true" 
         optionLabel="name"
         placeholder="Select SMS Platform"
         id="sms"
         />
      <label for="sms">SMS</label>
    </p-floatLabel>
  </div>


  <div class="w-5 mt-6">
    <p-floatLabel>
      <p-autoComplete 
         styleClass="w-full"
         [(ngModel)]="email" 
         [suggestions]="filteredEmailPlatformTypeList"  
         (completeMethod)="filterEmailPlatformType($event)" 
         [dropdown]="true" 
         optionLabel="name"
         placeholder="Select Email Platform"
         id="email"
         />
      <label for="email">Email</label>
    </p-floatLabel>
  </div>

  <div class="flex justify-content-end w-5 mt-6">
    <p-button (click)="onClickSubmit()" styleClass="text-xs" label="Submit" icon="pi pi-send text-xs" [outlined]="true" severity="secondary"></p-button>  
  </div>
 
  </div>
</div>