import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { MappingService } from '../../services/mapping/mapping.service';
import { ReferenceDataService } from '../../services/referencedata/reference-data.service';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-file-mappings',
    templateUrl: './file-mappings.component.html',
    styleUrl: './file-mappings.component.css'
})

export class FileMappingsComponent implements OnInit {

    public creditorsFormControl: FormControl = new FormControl();
    public searchMappingTypeExpression: string = '';
    public searchResult: any | null = null;
    public creditors: any | null = null;
    public filteredItems: any[] = [];
    public fileMappingTypes: any | null = null;
    public selectedCreditor: any | null = null;
    public isMasterMember: boolean = false;

    public constructor(public appService: AppService, private referenceDataService: ReferenceDataService, private mappingService: MappingService) { }

    public ngOnInit() {

        forkJoin(this.referenceDataService.getCreditors(),
                 this.mappingService.getDataFileMappingTypes()).subscribe(([creditors, fileMappingTypes]) => {

            
            this.creditors = creditors.map((i: any) => ({ // markup concatenation not supported for p-select so compose a long name in code 
                ...i,
                LongNameWithId: `${i.CompanyName} (${i.CreditorId})`
            }));

            this.creditors.unshift({ CreditorId: 'creditor/-1', CompanyName: '(Any)', LongNameWithId: '(Any)' });

            this.filteredItems = JSON.parse(JSON.stringify(this.creditors));

            this.fileMappingTypes = fileMappingTypes;

            this.isMasterMember = this.appService.CurrentSession.AuthenticatedUser.User.Roles.filter((i: string) => i.toUpperCase() == 'MASTER').length == 1;
        });
    }


    filterItems(event: any) {
        let filtered: any[] = [];
        let query = event.query;

        for (let i = 0; i < (this.creditors as any[]).length; i++) {
            let item = (this.creditors as any[])[i];
            if (item.LongNameWithId.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
                filtered.push(item);
            }
        }

        this.filteredItems = filtered;
    }


    public search = (): void => {

        let creditorNumericId: number = -1;

        if (this.selectedCreditor != null) {

            creditorNumericId = parseInt(this.selectedCreditor.CreditorId.split('/').pop());
        }

        this.mappingService.search(creditorNumericId, this.searchMappingTypeExpression).subscribe((r: any | null) => {

            if (r != null) {

                this.searchResult = r;
            }
        });
    }

    public create = (): void => {

        this.appService.navigateTo('filemapping/0');
    }

    public edit = (mappingSearchResult: any): void => {

        this.appService.navigateTo('filemapping/' + mappingSearchResult.DataFileMappingId.toString());
    }

    public importMappings = (): void => {

        this.appService.ShowModalDialog(ModalDialogSeverity.Warning, "Data Loss Warning", "This operation replaces any existing mappings with Raven mappings.  Are you absolutely sure you want to import all Raven mappings which will wipe out any existing SQL-Based mappings ?", "YES I AM ABSOLUTELY SURE", "NOT SURE").subscribe((response: string) => {

            if (response == "YES I AM ABSOLUTELY SURE") {

                this.appService.IsAppBusy = true;

                this.creditors.forEach((creditor: any) => {

                    this.fileMappingTypes.forEach(async (fileMappingType: any) => {

                        const creditorNumericId: number = Number(creditor.CreditorId.split('/').pop());
                        const fileMappingTypeId: number = fileMappingType.Id;

                        if (creditorNumericId > 0 && fileMappingTypeId > 0) {

                            await this.mappingService.importMapping(creditorNumericId, fileMappingTypeId);
                        }
                    });
                });

                this.appService.IsAppBusy = false;

                this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Mapping Import Completed", "Mapping Import Completed.  Check database for results", "OK", "");
            }
        });
    }

    public deleteMapping = (mappingSearchResult: any): void => {

        this.appService.ShowModalDialog(ModalDialogSeverity.Warning, "Confirm Deletion", "Are you sure you want to delete the " + mappingSearchResult.FileMappingTypeName + " for " + mappingSearchResult.CreditorCompanyName + "?", "YES DELETE MAPPING", "NOT SURE").subscribe((response: string) => {

            if (response != null && response == "YES DELETE MAPPING") {

                this.mappingService.delete(mappingSearchResult.DataFileMappingId).subscribe((r: any | null) => {

                    if (r != null) {

                        if (!r.IsDeleted) {

                            this.appService.ShowModalDialog(ModalDialogSeverity.Error, "Error Deleting Mapping", "There was an error deleting mapping " + mappingSearchResult.DataFileMappingId.toString(), "OK", "");

                        } else {

                            // remove the mapping from the result set
                            const deletedItemIndex = this.searchResult.map((i: any) => i.DataFileMappingId).indexOf(mappingSearchResult.DataFileMappingId, 0);

                            if (deletedItemIndex > -1) {

                                this.searchResult.splice(deletedItemIndex, 1);
                            }
                        }
                    }
                });
            }
        });
    }
}
