import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { ModalDialogData } from './interfaces/modal-dialog-data';
import { ModalDialogSeverity } from './enums/modal-dialog-severity';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})

export class AppService {

    private _baseUrl: string = '';

    public navigationNodes: any = [];
    public _navigationpanedynamicstyle: string = "navigationpane-hidden";
    public _shortcutspanedynamicstyle: string = "shortcutspane";
    public _headerLogoDynamicStyle: string = "headerlogo-hidden";
    public _footerdynamicstyle: string = "footer";
    public _footercontentdynamicstyle: string = "footerontent";
    public _issimplepresenttionmode: boolean = false;
    public _isappbusy: boolean = false;
    public _isnavigationcollapsed: boolean = true;
    public CurrentSession: any = null;
    public IsAppBusySubject: BehaviorSubject<boolean> = new BehaviorSubject<any>(undefined);
    public isNavigatorPinned: boolean = false;
    public randomAvatarBackgroundColor: string = "";
    public randomAvatarForegroundColor: string = "";
    @Output() hasLoggedOut = new EventEmitter<any>();
    public isLogInRoute: boolean = false;

    private _lastAppNavigatedRoute: string = "";

    public get IsSimplePresentationMode(): boolean {

        return this._issimplepresenttionmode;
    }

    public set IsSimplePresentationMode(value: boolean) {

        this._issimplepresenttionmode = value;
    }

    public get IsAppBusy(): boolean {

        return this._isappbusy;
    }

    public set IsAppBusy(value: boolean) {

        let previousvalue = this._isappbusy;

        this._isappbusy = value;

        if (this._isappbusy != previousvalue) {

            this.IsAppBusySubject.next(value);
        }
    }

    @Output() navigated = new EventEmitter<number>()

    public constructor(public router: Router,
                       public matdialog: MatDialog,
                       public http: HttpClient,
                       @Inject('API_BASE_URL') baseUrl: string) {

        this._baseUrl = baseUrl;
        this.setRandomAvatarColors();
    }

    public getPointers = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = "Home/pointers";
        const fullPath: string = this._baseUrl + apipath;
        const options = this.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getNavigationNodes = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = "Navigation/GetNavigationNodes/" + (this.CurrentSession == null ? "0" : this.CurrentSession.AuthenticatedUser.User.Id);
        const fullPath: string = this._baseUrl + apipath;

        if (this.CurrentSession) {

            const options = this.getHttpHeaderOptions();

            this.http.get<any>(fullPath, options).subscribe((response: any) => {

                this.navigationNodes = response;
                result.next(response);
                result.complete();

            }, error => {

                result.next(error);
            });

        } else {

            const nodes = this.getPublicNodes();
            this.navigationNodes = nodes;

            result.next(nodes);
            result.complete();
        }

        return result;
    }

    public getPublicNodes = (): any => {

        return '[{ "NavigationNodeId": 1, "Name": "Home", "Description": "Main home page", "DisplayText": "Home", "RoutePath": "", "MatIconName": "home_app_logo", "NavigationNodes": [] }]';
    }

    public getHttpHeaderOptions = (): any => {

        let result: any = null;
        const loggedUserSession = localStorage.getItem('userSession');

        if (loggedUserSession) {

            const loggedUserToken = JSON.parse(loggedUserSession).AuthenticatedUser.Token;

            result = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + loggedUserToken
                })
            };

        } else {

            result = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
        }

        return result;
    }

    public getNavigationNodeById = (navigationNodeId: number): any => {

        let result: any = this.getNavigationNodeByIdRecursively(this.navigationNodes, navigationNodeId);

        return result;
    }

    public getNavigationNodeByPath = (routePath: string): any => {

        let result: any = this.getNavigationNodeByPathRecursively(this.navigationNodes, routePath);

        return result;
    }

    public getNavigationNodeParentById = (navigationNodeId: number): any => {

        let result: any = this.getNavigationNodeParentByIdRecursively(this.navigationNodes, navigationNodeId);

        return result;
    }

    public getNavigationNodeByIdRecursively = (nodes: any, navigationNodeId: number): any => {

        let result: any = null;

        for (let i = 0; i < nodes.length; i++) {

            let n: any = nodes[i];

            if (n.NavigationNodeId == navigationNodeId) {

                result = n;
                break;

            } else {

                if (n.NavigationNodes.length > 0) {

                    result = this.getNavigationNodeByIdRecursively(n.NavigationNodes, navigationNodeId);

                    if (result != null) {

                        break;
                    }
                }
            }
        }

        return result;
    }

    private getNavigationNodeByPathRecursively = (nodes: any, routePath: string): any => {

        let result: any = null;

        for (let i = 0; i < nodes.length; i++) {

            let n: any = nodes[i];

            if (this.standardizePath(n.RoutePath) == this.standardizePath(routePath)) {

                result = n;
                break;

            } else {

                if (n.NavigationNodes.length > 0) {

                    result = this.getNavigationNodeByPathRecursively(n.NavigationNodes, routePath);

                    if (result != null) {

                        break;
                    }
                }
            }
        }

        return result;
    }

    private standardizePath = (path: string): string => {

        let result = path.toLowerCase();

        if (!path.startsWith('/')) {

            result = '/' + path;
        }

        return result;
    }

    private getNavigationNodeParentByIdRecursively = (nodes: any, navigationNodeId: number): any => {

        let result = null;

        for (let i = 0; i < nodes.length; i++) {

            let n: any = nodes[i];

            let parent: any = null;

            parent = n.NavigationNodes.find((i: { NavigationNodeId: number; }) => { return i.NavigationNodeId == navigationNodeId })

            if (parent != null) {

                result = n;
                break;

            } else {

                if (n.NavigationNodes.length > 0) {

                    result = this.getNavigationNodeParentByIdRecursively(n.NavigationNodes, navigationNodeId);

                    if (result != null) {

                        break;
                    }
                }
            }
        }

        return result;
    }

    public navigateTo = (destination: string) => {

        if (!this.isNavigatorPinned) {

            if (this._isnavigationcollapsed == false) {

                this.toggleNavigation();
            }
        }

        this.router.navigate([destination]);

        // find the navigation node and publish an event
        let routePath = destination;

        if (routePath.toLowerCase().startsWith('/role/')) { // should come from database (ie, association between collection and item navigation paths)
            routePath = '/rolesearch';
        }

        if (routePath.toLowerCase().startsWith('/user/')) { // should come from database (ie, association between collection and item navigation paths)
            routePath = '/usersearch';
        }

        let currentNavigationNode = this.getNavigationNodeByPath(routePath);

        if (currentNavigationNode != null) {

            this.navigated.emit(currentNavigationNode);
        }

        this._lastAppNavigatedRoute = routePath;
    }

    public broadcastLastAppNavigatedNode() {

        let lastAppNavigatedNode = this.getNavigationNodeByPath(this._lastAppNavigatedRoute);

        if (lastAppNavigatedNode != null) {

            this.navigated.emit(lastAppNavigatedNode);
        }
    }

    public onScroll = (e: any) => {

        try {

            let height: number = e.target.offsetHeight;
            let scrollheight: number = e.target.scrollHeight;
            let scrolltop: number = e.target.scrollTop;
            let scrolldegree: number = scrolltop / (scrollheight - height);

            if (isNaN(scrolldegree)) {

                if (scrolldegree > 0.1) { // collapse footer

                    this._footerdynamicstyle = "footer";
                    this._footercontentdynamicstyle = "footercontent";

                    //if (window.innerWidth < 700) {

                    //    window.document.body.requestFullscreen();
                    //}

                } else {

                    this._footerdynamicstyle = "footer-collapsed";
                    this._footercontentdynamicstyle = "footercontent-collapsed";

                    //if (window.innerWidth < 700) {

                    //    window.document.exitFullscreen();
                    //}
                }
            }

        } catch (e) {

        }
    }

    public toggleNavigation = () => {

        if (this._isnavigationcollapsed == false) {

            this._navigationpanedynamicstyle = "navigationpane-hidden";
            this._shortcutspanedynamicstyle = "shortcutspane";
            this._headerLogoDynamicStyle = "headerlogo-hidden";
            this._isnavigationcollapsed = true;

        } else {

            this._navigationpanedynamicstyle = "navigationpane";
            this._shortcutspanedynamicstyle = "shortcutspane-hidden";
            this._headerLogoDynamicStyle = "headerlogo";
            this._isnavigationcollapsed = false;
        }
    }

    public formatHttpErrorResponse = (r: HttpErrorResponse): string => {

        let result: string = '';

        result += 'Error:' + r.error + '\r\n';
        result += 'Message: ' + r.message + '\r\n';
        result += 'Status:' + r.status + '\r\n';
        result += 'URL:' + r.url + '\r\n';

        return result;
    }

    public ShowModalDialog = (severity: ModalDialogSeverity,
                              title: string,
                              message: string,
                              acceptbuttontext: string,
                              cancelbuttontext: string): Observable<string> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<any>(undefined); // = 'OK';

        const acceptbuttoncaption = (acceptbuttontext == null || acceptbuttontext == '') ? 'OK' : acceptbuttontext;
        const cancelbuttoncaption = cancelbuttontext == null ? '' : cancelbuttontext;

        let dialogdata: ModalDialogData = {
            Severity: severity,
            Title: title,
            Message: message,
            AcceptButtonText: acceptbuttoncaption,
            CancelButtonText: cancelbuttoncaption
        }

        const dialogconfiguration = new MatDialogConfig();

        dialogconfiguration.disableClose = false;
        dialogconfiguration.autoFocus = true;
        dialogconfiguration.data = dialogdata;

        const dialog = this.matdialog.open(ModalDialogComponent, dialogconfiguration);

        dialog.afterClosed().subscribe((response) => {

            result.next(response);
            result.complete();
        });

        return result;
    }

    public notifyUnderConstruction = async (componentname: string) => {

        const random = Math.floor(Math.random() * 4) + 1;
        let severity: ModalDialogSeverity = ModalDialogSeverity.Info;
        let message: string = '';
        switch (random) {
            case 1:
                severity = ModalDialogSeverity.Info;
                message = 'Here is some Information';
                break;
            case 2:
                severity = ModalDialogSeverity.Success;
                message = 'Good news, it was Successful';
                break;
            case 3:
                severity = ModalDialogSeverity.Warning;
                message = 'This is a warning';
                break;
            case 4:
                severity = ModalDialogSeverity.Error;
                message = 'Dang it ! There was an Error';
                break;
        }

        this.IsAppBusy = true;

        await new Promise(resolve => setTimeout(resolve, 1500));

        this.IsAppBusy = false;

        this.ShowModalDialog(severity, message, "The component '" + componentname + "' is not ready yet.  Thanks for stopping by.", "Go back to what I was doing", "");
    }

    public showHourglass = async (displaySeconds: number) => {

        this.IsAppBusy = true;

        await new Promise(resolve => setTimeout(resolve, displaySeconds * 1000));

        this.IsAppBusy = false;
    }

    public updateCurrentLocation = (currentNodePath: string): void => {


    }

    public showWildcardHint = (frontRearBoth: number, actualSearchText: string = "") => {

        let message = "Searches on this field apply a wildcard to";

        switch (frontRearBoth) {

            case 1:

                message += " the front of the search term.  For example, entering 'mill' would return 'MacMill' and 'McMill'.";
                break;

            case 2:

                message += " the the rear of the search term.  For example, entering 'mill' would return 'Mills' and 'Miller'.";
                break;

            case 3:

                message += " to both the front and rear of the search term.  For example, entering 'mill' would return 'MacMillan' and 'Mills'.";
                break;
        }

        if (actualSearchText.trim() != "") {

            message += "Since you have entered a search term, the search the search being applied in this case will be '" + ((frontRearBoth == 1 || frontRearBoth == 3) ? "*" : "") + actualSearchText + ((frontRearBoth == 2 || frontRearBoth == 3) ? "*" : "");
        }

        this.ShowModalDialog(ModalDialogSeverity.Info, "Search Hint", message, "OK", "");
    }

    public storeLoggedUser = (user: any): any => {

        const sessionGuid = uuidv4();
        const userSession: any = { "SessionId": sessionGuid, "AuthenticatedUser": user };

        localStorage.setItem('userSession', JSON.stringify(userSession));
        this.CurrentSession = userSession;

        return userSession;
    }

    public getCurrentSession(){
        const userSession = localStorage.getItem('userSession');
        if(userSession){
            const userSessionObj = JSON.parse(userSession);
            return userSessionObj;
        }
        return null;
    }

    public syncWildcardSuffixPosition = (event:any, isBlur: boolean): void => {

        try {

            let inputElement = event.srcElement;
            let wildcardSuffixElement = inputElement.parentElement.parentElement.getElementsByClassName('wildcard-suffix')[0];

            const canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");

            if (context != null) {

                context.font = this.getFont(event.srcElement);
                let textWidth: number = context.measureText(event.srcElement.value).width;
                let inputElementLeft = inputElement.offsetLeft || 0;
                let distance = (inputElement.parentElement.parentElement.offsetWidth - inputElementLeft) - 28;

                wildcardSuffixElement.style.position = 'relative';

                if (isBlur) { // cursor is leaving the input, find the best spot for the trailing wildcard element

                    if (textWidth == 0) { // this means the label is occuppying the text area

                        textWidth = 90; // the text width of the label occupying the input field zone
                        wildcardSuffixElement.style.left = '-' + (distance - textWidth) + 'px';

                    } else { // theres some text in the input, position based on text

                        wildcardSuffixElement.style.left = '-' + (distance - textWidth) + 'px';
                    }

                } else { // the cursor either entered the input or has added or removed a char in the input, measure the text width and place the wildcard element

                    wildcardSuffixElement.style.left = '-' + (distance - textWidth) + 'px';
                }
            }

        } catch (e) {

            // 
        }
    }

    private getFont = (element: any): string => {

        let result = "";

        const prop = ["font-style", "font-variant", "font-weight", "font-size", "font-family"];

        for (var x in prop) {
            result += window.getComputedStyle(element, null).getPropertyValue(prop[x]) + " ";
        }

        return result;
    }

    public logout = (): void => {

        localStorage.removeItem("userSession");
        this.CurrentSession = null;
        this.hasLoggedOut.emit(new Date());

        this.navigateTo("");
    }

    public showPendingFeatureMessage = () => {

        this.ShowModalDialog(ModalDialogSeverity.Success, "Pending Feature", "This is feature that has yet-to-be implemented", "OK", "");
    }

    private getRandomColor = (): string => {

        let letters = '0123456789ABCDEF';
        let result = '#';

        for (let i = 0; i < 6; i++) {

            result += letters[Math.floor(Math.random() * 16)];
        }

        return result;
    }

    private getRandomGrey = (): string => {

        let v = (Math.random() * (256) | 0).toString(16);

        return "#" + v + v + v;
    }

    private pickTextColor = (bgColor: string, lightColor: string, darkColor: string) => {

        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;

        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];

        var c = uicolors.map((col) => {

            if (col <= 0.03928) {
                return col / 12.92;
            }

            return Math.pow((col + 0.055) / 1.055, 2.4);
        });

        var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);

        return (L > 0.179) ? darkColor : lightColor;
    }

    public setRandomAvatarColors = (): void => {

        this.randomAvatarBackgroundColor = this.getRandomColor();
        this.randomAvatarForegroundColor = this.pickTextColor(this.randomAvatarBackgroundColor, "white", "black");
    }
}
