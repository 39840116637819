import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../app.service';
import { of, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    private _baseUrl: string = '';
    @Output() loggedIn = new EventEmitter<any>();
    @Output() loggedOut = new EventEmitter<boolean>();

    public constructor(public appService: AppService,
                       public http: HttpClient,
                       @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public login = (credentials: any): Observable<any> => {

        let result: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
        const apiPath: string = "auth/login";
        const fullPath: string = this._baseUrl + apiPath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<boolean>(fullPath, credentials, options).subscribe((r: any) => { 

            result.next(r);
            result.complete();

            this.loggedIn.emit(r);

        }, error => {

            result.next(error);
        });

        return result;
    }

    public logAppSession = (sessionGuid: string, userId: string, userName: string): Observable<any> => {

        let result: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
        const apiPath: string = "auth/logsession";
        const fullPath: string = this._baseUrl + apiPath;
        const options = this.appService.getHttpHeaderOptions();
        const sessionLogRequest: any = { SessionGuid: sessionGuid, UserId: userId, Username: userName };

        this.http.post<boolean>(fullPath, sessionLogRequest, options).subscribe((r: any) => { 

            result.next(r);
            result.complete();

            this.loggedIn.emit(r);

        }, error => {

            result.next(error);
        });

        return result;
    }

    public logout = (): void => {

        this.loggedOut.emit(true);
    }
}
