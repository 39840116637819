<div class="main-content" style="background-color:#ffe6ff;">
    <div class="grid-area:breadcrumbs;" style="background-color:white;">(breadcrumbs....)</div>
    <div class="grid-area:content;" style="overflow:scroll;background-color:white;">
        <!--<router-outlet></router-outlet>-->
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
        <div>xxx</div>
        <br />
    </div>
    <div class="grid-area:output;" style="background-color:white;">
        (output)
    </div>
</div>
