import { Component } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'prime-dialog',
  templateUrl: './prime-dialog.component.html',
  styleUrl: './prime-dialog.component.css'
})
export class PrimeDialogComponent {
  public constructor(public appService: AppService){}
}
