import { Inject, Injectable } from '@angular/core';
import { AppService } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MappingService {

    private _etlApiBaseUrl: string = '';

    public constructor(private appService: AppService, public http: HttpClient, @Inject('ETL_API_BASE_URL') baseurl: string) {

        this._etlApiBaseUrl = baseurl;
    }

    public getDataFileMappingTypes = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'api/mapping/getfilemappingtypes';
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getDataSourceFieldMappingTypes = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'api/mapping/getdatasourcefieldmappingtypes';
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getStringFormatTypes = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'api/mapping/getstringformattypes';
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public search = (creditorNumericId: number, mappingTypeSearchExpression: string): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'api/mapping/search?creditorNumericId=' + creditorNumericId.toString() + '&mappingTypeSearchExpression=' + mappingTypeSearchExpression;
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getDataFileMappingDocumentById = (dataFileMappingId: number): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'api/mapping/getsqldocumentbyid?dataFileMappingId=' + dataFileMappingId.toString();
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public save = (dataFileMapping: any | null): Observable<any | null> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'api/mapping/updatesqldocument';
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, dataFileMapping, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public create = (creditorNumericId: number, fileMappingTypeId: number): Observable<any | null> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'api/mapping/create/' + creditorNumericId.toString() + '/' + fileMappingTypeId.toString();
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public delete = (dataFileMappingId: number): Observable<any | null> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'api/mapping/delete?filemappingid=' + dataFileMappingId.toString();
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.delete<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public importMapping = async (creditorId: number, fileMappingTypeId: number): Promise<boolean> => {

        const apipath: string = 'api/mapping/import?creditorIds=' + creditorId.toString() + '&FileMappingTypeIds=' + fileMappingTypeId.toString();
        const fullPath: string = this._etlApiBaseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        const importRequest = { creditorIds: [creditorId], FileMappingTypeIds: [fileMappingTypeId] };

        await this.http.post<any>(fullPath, importRequest, options).toPromise();

        return true;
    }
}
