<div class="breadcrumb">
    <span *ngFor="let crumb of crumbs; let i = index">
        <button mat-button (click)="appService.navigateTo(crumb.RoutePath)">
            <span class="material-symbols-outlined" matTooltip="{{crumb.Name}}" style="font-size:14pt;">
                {{crumb.IconName}}
            </span>
            <span class="breadcrumb-label">{{crumb.Name}}</span>
        </button>
        <span class="material-symbols-outlined" style="font-size: 14pt;position:relative;top:4px;">
            play_arrow
        </span>
    </span>
    <span *ngIf="navigationNode">
        <button mat-button (click)="appService.navigateTo(navigationNode.RoutePath)">
            <span class="material-symbols-outlined" matTooltip="{{navigationNode.DisplayText}}" style="font-size:14pt;">
                {{navigationNode.MatIconName}}
            </span>
            <span class="breadcrumb-label">{{navigationNode.DisplayText}}</span>
        </button>
    </span>
</div>
