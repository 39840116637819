import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from '../../app.service';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule, MatChipListbox } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { RoleService } from '../../services/role/role.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../services/user/user.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-role-edit',
    templateUrl: './role-edit.component.html',
    styleUrls: ['./role-edit.component.css']
})

export class RoleEditComponent implements OnInit {

    public itemId: string = '';
    public itemData: any = null;
    public resultsUsersMatTableDataSource: any = null;
    public resultsClaimsMatTableDataSource: any = null;
    public displayedClaimsColumns: string[] = ['Avatar', 'Type', 'Value', 'RemoveClaim'];
    public displayedUsersColumns: string[] = ['Avatar', 'UserName', 'RemoveUser'];
    public availableUsers: any = [];
    public selectedUser: any = null;
    public availableClaims: any = [];
    public selectedClaim: any = null;
    public enteredClaimType: string = '';
    public enteredClaimValue: string = '';
    public usersResultsClass: string = 'display-none';
    public claimsResultsClass: string = 'display-none'; public editHistorys: any = [{ EventDateTime: "12/05/2023", EventType: "Created", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "01/05/2024", EventType: "Modified", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "Deleted", EventUser: "Roy.Munson", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "UnDeleted", EventUser: "Joe.Banks", EventCommentary: "This should not have been deleted.  Reactivating" }];

    public usersTabFormControl: FormControl = new FormControl();
    public claimsTabFormControl: FormControl = new FormControl();

    @ViewChild('userSort') userSort: any;
    @ViewChild('claimSort') claimSort: any;

    public constructor(public appService: AppService,
                       public roleService: RoleService,
                       private userService: UserService,
                       private route: ActivatedRoute,
                       private location: Location) {

        const parameters = route.params;

        if (parameters != null) {

            parameters.subscribe(p => {

                this.itemId = p['roleId'];
            });
        }
    }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        forkJoin(this.userService.search({ CreditorIds: [], FirstName: '', LastName: '', EmailAddress: '' })).subscribe(([users]) => {

            this.availableUsers = users;
            this.loadItem();
            this.appService.IsAppBusy = false;
        });
    }

    public loadItem = (): void => {

        this.appService.IsAppBusy = true;

        if (this.itemId == '0') {

            this.roleService.getEmptyRole().subscribe((r: any) => {

                if (r != '') {

                    this.appService.IsAppBusy = false;

                    if (r instanceof HttpErrorResponse) {

                        this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                    } else {

                        this.itemData = r;
                    }
                }
            });

        } else {

            this.roleService.getById(this.itemId).subscribe((r: any) => {

                if (r != '') {

                    this.appService.IsAppBusy = false;

                    if (r instanceof HttpErrorResponse) {

                        this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                    } else {

                        this.itemData = r;

                        this.setUsersTableDataSource();
                        this.resultsUsersMatTableDataSource.sort = this.userSort;

                        this.setClaimsTableDataSource();
                        this.resultsClaimsMatTableDataSource.sort = this.claimSort;

                        this.usersResultsClass = this.itemData.Users.length == 0 ? 'display-none' : 'display-block';
                        this.claimsResultsClass = this.itemData.Claims.length == 0 ? 'display-none' : 'display-block';
                    }
                }
            });
        }
    }

    private setUsersTableDataSource = (): void => {

        this.resultsUsersMatTableDataSource = new MatTableDataSource(this.itemData.Users);
    }

    private setClaimsTableDataSource = (): void => {

        this.resultsClaimsMatTableDataSource = new MatTableDataSource(this.itemData.Claims);
    }

    public save = (): void => {

        this.appService.IsAppBusy = true;

        let isCreatingNew = this.itemData.Id == '00000000-0000-0000-0000-000000000000';

        this.roleService.save(this.itemData).subscribe((r: any) => {

            if (r != '') {

                this.appService.IsAppBusy = false;

                if (r instanceof HttpErrorResponse) {

                    this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                } else {

                    this.itemData = r;
                    this.itemId = this.itemData.Id;
                    this.resultsUsersMatTableDataSource = new MatTableDataSource(this.itemData.Users);
                    this.resultsClaimsMatTableDataSource = new MatTableDataSource(this.itemData.Claims);

                    if (isCreatingNew) {

                        this.location.replaceState('/role/' + this.itemData.Id);
                    }
                }
            }
        });
    }

    public delete = (): void => {

        let proceedResponse = "PROCEED TO DELETE";

        this.appService.ShowModalDialog(ModalDialogSeverity.Warning,
                                        "Confirm Deletion",
                                        "Are you sure you want to delete role " + this.itemData.Name + ".  If you are 100% sure, click PROCEEED TO DELETE, otherwise CANCEL",
                                        proceedResponse, 
                                        "CANCEL").subscribe((response) => {

            if (response == proceedResponse) {

                this.appService.IsAppBusy = true;

                this.roleService.delete(this.itemData.Id).subscribe((r: any) => {

                    if (r != '') {

                        this.appService.IsAppBusy = false;

                        if (r instanceof HttpErrorResponse) {

                            this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                        } else {

                            this.appService.navigateTo('/rolesearch');
                        }
                    }
                });
            }
        });
    }

    public addUser = (): void => {

        this.itemData.Users.push(this.selectedUser);
        this.setUsersTableDataSource();

        const itemIndex = this.availableUsers.indexOf(this.selectedUser, 0);

        if (itemIndex > -1) {

            this.availableUsers.splice(itemIndex, 1);
        }

        this.selectedUser = null;
        this.usersResultsClass = this.itemData.Users.length == 0 ? 'display-none' : 'display-block';
    }

    public editUser = (user: any): void => {

        this.appService.navigateTo('/user/' + user.Id);
    }

    public removeUser = (user: any): void => {

        const itemIndex = this.itemData.Users.indexOf(user, 0);

        if (itemIndex > -1) {

            this.itemData.Users.splice(itemIndex, 1);
            this.setUsersTableDataSource();
        }

        this.availableUsers.push(user);
        this.usersResultsClass = this.itemData.Users.length == 0 ? 'display-none' : 'display-block';
    }

    public addClaim = (): void => {

        if (this.enteredClaimType.trim() == '' || this.enteredClaimValue.trim() == '') {

            this.appService.ShowModalDialog(ModalDialogSeverity.Warning, "Missing Data", "Unable to add claim due to missing information.  Both Type and Value must be filled in.", "OK", "");

        } else {

            this.itemData.Claims.push({ Type: this.enteredClaimType, Value: this.enteredClaimValue, ValueType: "", Issuer: "", OriginalIssuer: "" });
            this.enteredClaimType = '';
            this.enteredClaimValue = '';
            this.resultsClaimsMatTableDataSource = new MatTableDataSource(this.itemData.Claims);
        }

        this.claimsResultsClass = this.itemData.Claims.length == 0 ? 'display-none' : 'display-block';
    }

    public editClaim = (claim: any): void => {

    }

    public removeClaim = (claim: any): void => {

        var claimIndex = this.itemData.Claims.map((i: any) => i.Type + ':' + i.Value).indexOf(claim.Type + ':' + claim.Value, 0);

        if (claimIndex > -1) {

            this.itemData.Claims.splice(claimIndex, 1);
            this.resultsClaimsMatTableDataSource = new MatTableDataSource(this.itemData.Claims);
        }

        this.claimsResultsClass = this.itemData.Claims.length == 0 ? 'display-none' : 'display-block';
    }
}
