<div class="FullHeightContentContainer">
    <div class="FullHeightContenttHeader">
        <table style="width:100%;">
            <tr>
                <td rowspan="2" style="padding-left:12px;width:1px;">
                    <span class="material-symbols-outlined" style="font-size: 32pt;">
                        receipt_long
                    </span>
                </td>
                <td style="padding-left:38px;">
                    <mat-card-title>Monthly Billing Report</mat-card-title>
                </td>
            </tr>
            <tr>
                <td style="padding-left:42px;">
                    <mat-card-subtitle>Generate billing details for each creditor for a given month and year.  Results delivered as a zip file .</mat-card-subtitle>
                </td>
            </tr>
        </table>
    </div>
    <div class="FullHeightContentContent">
        <mat-form-field class="" style="width:320px; padding-left: 12px;">
            <mat-label>Year</mat-label>
            <input matInput #year type="number" [(ngModel)]="searchParameters.Year" />
        </mat-form-field>
        <br />
        <mat-form-field class="" style="width:320px; padding-left: 12px;">
            <mat-label>Month</mat-label>
            <mat-select [(ngModel)]="searchParameters.Month">
                <mat-option *ngFor="let month of availableMonths" [value]="month.Ordinal">{{month.Name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <br />
        <span class="ActionButtons">
            <button mat-button (click)="runReport()">
                <span class="material-symbols-outlined">
                    dynamic_form
                </span>
                RUN REPORT
            </button>
            <!--<button *ngIf="isReportPendingRetrieval" mat-button (click)="getFile()">
                <span class="material-symbols-outlined">
                    csv
                </span>
                RETRIEVE FILE
            </button>-->
        </span>
    </div>
    <div class="FullHeightContentFooter">
    </div>
</div>
