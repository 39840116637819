import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { AppService } from '../../app.service';
import { FlatNode } from '../../interfaces/flat-node';
import { NavigationNode } from '../../interfaces/navigation-node';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'app-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls: ['./shortcuts.component.css']
})

export class ShortcutsComponent implements OnInit {

    private _transformer = (node: NavigationNode, level: number) => {
        return {
            id: node.NavigationNodeId,
            expandable: !!node.NavigationNodes && node.NavigationNodes.length > 0,
            name: node.DisplayText,
            route: node.RoutePath,
            level: level,
            color: "black",
            maticon: node.MatIconName
        };
    };

    public treeControl = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable,
    );

    public treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.NavigationNodes
    );

    public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    public hasChild = (_: number, node: FlatNode) => node.expandable;

    public constructor(public appService: AppService, private authService: AuthService) { }

    public ngOnInit(): void {

        this.authService.loggedIn.subscribe((currentUser: any) => {

            this.loadNodes();
        });

        this.authService.loggedOut.subscribe((r: any) => {

            const publicNodes = this.appService.getPublicNodes();

            this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
            this.dataSource.data = publicNodes;
        });

        this.loadNodes();
    }

    private loadNodes = (): void => {

        this.appService.getNavigationNodes().subscribe((nodes: any) => {

            if (nodes != null) {

                if (nodes.length > 0) {

                    this.dataSource.data = nodes;
                }
            }
        });
    }
}
