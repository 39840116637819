import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { saveAs } from 'file-saver';
import { AppService } from '../../app.service';
import { ReportsService } from '../../services/reports/reports.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';

@Component({
    selector: 'app-monthly-billing-report',
    templateUrl: './monthly-billing-report.component.html',
    styleUrls: ['./monthly-billing-report.component.css']
})

export class MonthlyBillingReportComponent implements OnInit {

    public isReportPendingRetrieval: boolean = false;
    public monthsFormControl:FormControl = new FormControl();
    public availableMonths: any = [ { "Name": "January", "Ordinal": 1 }, { "Name": "February", "Ordinal": 2 }, { "Name": "March", "Ordinal": 3 }, { "Name": "April", "Ordinal": 4 }, { "Name": "May", "Ordinal": 5 }, { "Name": "June", "Ordinal": 6 }, { "Name": "July", "Ordinal": 7 }, { "Name": "August", "Ordinal": 8 }, { "Name": "September", "Ordinal": 9 }, { "Name": "October", "Ordinal": 10 }, { "Name": "November", "Ordinal": 11 }, { "Name": "December", "Ordinal": 12 } ];
    public searchParameters: any = { "Year": 0, "Month": 0 };
    public year: number = 0;
    public month: number = 0;

    public constructor(public appService: AppService, 
                       public reportsService: ReportsService) {
    }

    public ngOnInit(): void {

        const now = new Date();

        // set defaults to prior month
        this.searchParameters.Year = now.getMonth() == 0 ? now.getFullYear() - 1 : now.getFullYear();
        this.searchParameters.Month = now.getMonth() == 0 ? 12 : now.getMonth();
    }

    public runReport = () => {

        const proceedResponse = "RUN IT !";

        this.appService.ShowModalDialog(ModalDialogSeverity.Info, 
                                        "Confirm Criteria", 
                                        "Please note that this report for " + this.availableMonths[this.searchParameters.Month - 1].Name + ", " + this.searchParameters.Year + " may take a few minutes to process.  The result are output as a Zip file directly to your Downloads folder.", 
                                        proceedResponse, 
                                        "CANCEL").subscribe((response) => {

            if (response == proceedResponse) {

                this.appService.IsAppBusy = true;
                this.reportsService.getMonthlyBillingReportFile(this.searchParameters).subscribe((f: any) => {

                    if (f != null) {

                        saveAs(f, "Remitter_Client_Billing_" + this.searchParameters.Year + "_" + this.availableMonths[this.searchParameters.Month - 1].Name + ".zip");

                        this.appService.IsAppBusy = false;

                        this.appService.ShowModalDialog(ModalDialogSeverity.Success, "Report Completed", "The monthly billing report for " + this.availableMonths[this.searchParameters.Month - 1].Name + ", " + this.searchParameters.Year + " is ready.  Please check your downloads folder", "OK", "");
                    }
                });
            }
        });
    }
}
