import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

    public credentials: any = { "Email": "", "Password": "" };
    public isPasswordHidden: boolean = true;
    public returnUrl: string = "";

    public constructor(public appService: AppService,
                       public authService: AuthService,
                       private route: ActivatedRoute) {

        this.route.queryParams.subscribe(params => {

            this.returnUrl = params['returnUrl'];
        });
    }

    public ngOnInit(): void { 
        this.appService.isLogInRoute = true;
    }

    public login = (): void => {

        this.appService.IsAppBusy = true;

        this.authService.login(this.credentials).subscribe((user: any) => {

            if (user != '') {

                this.appService.IsAppBusy = false;

                if (user.Token == null) {

                    this.appService.ShowModalDialog(ModalDialogSeverity.Warning, "Login Result", "Unable to login with the given credentials", "OK", "");

                } else {

                    const userSession = this.appService.storeLoggedUser(user);

                    this.authService.logAppSession(userSession.SessionId, userSession.AuthenticatedUser.User.Id, userSession.AuthenticatedUser.User.Username);

                    if (!!this.returnUrl) {
                        this.appService.navigateTo(this.returnUrl);
                    } else {
                        this.appService.navigateTo('/userprofile');
                    }
                }
            }
        });
    }

    public logout = (): void => {

        localStorage.removeItem('userSession');
        this.appService.CurrentSession = null;
    }
}
