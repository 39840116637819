import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'app-user-badge',
    templateUrl: './user-badge.component.html',
    styleUrls: ['./user-badge.component.css']
})

export class UserBadgeComponent implements OnInit {

    public userInitials: string = "??";
    public avatarBackgroundColor: string = "#000";
    public avatarTextColor: string = "#FFF";


    public constructor(public appService: AppService, private authService: AuthService) { }

    public ngOnInit(): void {

        this.authService.loggedIn.subscribe((currentUser: any) => {

            this.avatarBackgroundColor = this.appService.randomAvatarBackgroundColor;
            this.avatarTextColor = this.appService.randomAvatarForegroundColor;

            this.userInitials = currentUser.User.FirstName.substring(0, 1) + currentUser.User.LastName.substring(0, 1);
        });
    }

    public showProfile = (): void => {

        this.appService.navigateTo('/userprofile');
    }

    public showSettings = (): void => {

        this.appService.showPendingFeatureMessage();
    }

    public logout = (): void => {

        this.appService.logout();
        this.authService.logout();
    }
}
