<mat-drawer-container class="example-container" autosize>
    <mat-drawer #historyDrawer class="example-sidenav" mode="side" position="end">
        <mat-card>
            <mat-card-header>
                <div mat-card-avatar class="mat-card-header-text">
                    <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                        history
                    </div>
                </div>
                <mat-card-title>History</mat-card-title>
                <mat-card-subtitle>Edit History</mat-card-subtitle>
            </mat-card-header>
            <mat-vertical-stepper [linear]="false" #stepper>
                <mat-step *ngFor="let editHistory of editHistorys">
                    <ng-template matStepLabel>{{editHistory.EventDateTime}}</ng-template>
                    <mat-chip>{{editHistory.EventType}}</mat-chip>
                    <mat-chip>{{editHistory.EventUser}}</mat-chip>
                </mat-step>
            </mat-vertical-stepper>
            <mat-card-content>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
        </mat-card>
    </mat-drawer>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="mat-card-header-text">
                <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                    work
                </div>
            </div>
            <mat-card-title>{{itemData.Name}}</mat-card-title>
            <mat-card-subtitle>Role: Id-{{itemData.Id}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form *ngIf="itemData">
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Name</mat-label>
                    <input matInput #emailAddress maxlength="256" [(ngModel)]="itemData.Name" width="220" name="Name" />
                </mat-form-field>
                <br />
                <mat-form-field *ngIf="itemId != '0'" class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Normalized Name</mat-label>
                    <input matInput #firstName maxlength="256" [(ngModel)]="itemData.NormalizedName" width="220" name="NormalizedName" readonly style="color:gray;" />
                </mat-form-field>
                <mat-form-field *ngIf="itemId != '0'" class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Concurrency Stamp</mat-label>
                    <input matInput #lastName maxlength="256" [(ngModel)]="itemData.ConcurrencyStamp" width="220" name="ConcurrencyStamp" readonly style="color:gray;" />
                </mat-form-field>
            </form>
            <mat-tab-group *ngIf="itemId != '0'" mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span matBadge="{{this.itemData.Users.length == 0 ? '' : this.itemData.Users.length}}" matBadgeSize="small" matBadgeOverlap="false">
                            Users
                        </span>
                    </ng-template>
                    <br />
                    <form>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>Available Users</mat-label>
                            <mat-select [formControl]="usersTabFormControl" [(ngModel)]="selectedUser">
                                <mat-option *ngFor="let user of availableUsers" [value]="user">{{user.FirstName}} {{user.LastName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-button (click)="addUser()" [disabled]="this.selectedUser == null">
                            <span class="material-symbols-outlined">
                                add
                            </span>
                            ADD USER
                        </button>
                    </form>
                    <br />
                    <table mat-table matSort #roleSort="matSort" [dataSource]="resultsUsersMatTableDataSource" class="mat-elevation-z8 {{usersResultsClass}}">
                        <ng-container matColumnDef="Avatar">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item" (click)="editUser(item)">
                                <span class="material-symbols-outlined" style="font-size: 18pt;">
                                    person
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="UserName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> UserName </th>
                            <td mat-cell *matCellDef="let item" (click)="editUser(item)"> {{item.FirstName}} {{item.LastName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="RemoveUser">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item" (click)="removeUser(item)">
                                <span class="material-symbols-outlined" style="font-size: 18pt;">
                                    delete
                                </span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedUsersColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedUsersColumns;"></tr>
                    </table>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span matBadge="{{this.itemData.Claims.length == 0 ? '' : this.itemData.Claims.length}}" matBadgeSize="small" matBadgeOverlap="false">
                            Claims
                        </span>
                    </ng-template>
                    <br />
                    <form>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>Claim Type</mat-label>
                            <input matInput #firstName maxlength="256" [(ngModel)]="enteredClaimType" width="220" name="EnteredClaimType" />
                        </mat-form-field>
                        <mat-form-field style="width: 220px; padding-left: 12px;">
                            <mat-label>Claim Value</mat-label>
                            <input matInput #lastName maxlength="256" [(ngModel)]="enteredClaimValue" width="220" name="EnteredClaimValue" />
                        </mat-form-field>
                        <button mat-button (click)="addClaim()" [disabled]="this.enteredClaimType.trim() == '' || this.enteredClaimValue.trim() == ''">
                            <span class="material-symbols-outlined">
                                add
                            </span>
                            ADD CLAIM
                        </button>
                    </form>
                    <br />
                    <table mat-table matSort #claimSort="matSort" [dataSource]="resultsClaimsMatTableDataSource" class="mat-elevation-z8 {{claimsResultsClass}}">
                        <ng-container matColumnDef="Avatar">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item" (click)="editClaim(item)">
                                <span class="material-symbols-outlined" style="font-size: 18pt;">
                                    interests
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                            <td mat-cell *matCellDef="let item" (click)="editClaim(item)"> {{item.Type}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Value">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
                            <td mat-cell *matCellDef="let item" (click)="editClaim(item)"> {{item.Value}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Issuer">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Issuer </th>
                            <td mat-cell *matCellDef="let item" (click)="editClaim(item)"> {{item.Issuer}} </td>
                        </ng-container>
                        <ng-container matColumnDef="RemoveClaim">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item" (click)="removeClaim(item)">
                                <span class="material-symbols-outlined" style="font-size: 18pt;">
                                    delete
                                </span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedClaimsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedClaimsColumns;"></tr>
                    </table>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span matBadge="" matBadgeSize="small" matBadgeOverlap="false">
                            Navigation Nodes
                        </span>
                    </ng-template>
                    <br />
                    <span>
                        <span class="material-symbols-outlined">
                            draw_abstract
                        </span>
                        (Not implemented)
                    </span>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button (click)="save()">
                <span class="material-symbols-outlined">
                    save
                </span>
                SAVE
            </button>
            <button *ngIf="itemId != '0'" mat-button (click)="delete()">
                <span class="material-symbols-outlined">
                    delete_forever
                </span>
                DELETE
            </button>
            <button *ngIf="itemId != '0'" mat-button (click)="historyDrawer.toggle()">
                <span class="material-symbols-outlined">
                    history
                </span>
                CHANGE LOG
            </button>
        </mat-card-actions>
        <mat-card-footer>
            <!--<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
        </mat-card-footer>
    </mat-card>
</mat-drawer-container>
