import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../../app.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

    public constructor(private router: Router, private appService: AppService, private authService: AuthService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const session = this.appService.CurrentSession
        let result: boolean = false;

        if (session) {

            //if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
            if (false) { // if not allowed due to insufficient role(s)
                
                this.router.navigate(['/login']); // role not authorised so redirect to home page
                result = false;

            } else {

                result = true;
            }

        } else {

            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

            result = false;
        }

        return result;
    }
}
