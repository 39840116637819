import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from '../../app.service';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule, MatChipListbox } from '@angular/material/chips';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoleService } from '../../services/role/role.service';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.css'],
})

export class UserEditComponent implements OnInit {

    public itemId: string = "";
    public itemData: any = null;
    public resultsRolesMatTableDataSource: any = null;
    public resultsClaimsMatTableDataSource: any = null;
    public displayedClaimsColumns: string[] = ['Avatar', 'Type', 'Value', 'Issuer', 'RemoveClaim'];
    public displayedRolesColumns: string[] = ['Avatar', 'RoleName', 'RemoveRole'];
    public availableRoles: any = null;
    public selectedRole: any = null;
    public enteredClaimType: string = '';
    public enteredClaimValue: string = '';
    public rolesResultsClass: string = 'display-none';
    public claimsResultsClass: string = 'display-none';
    public rolesCountBadge: string = '';
    public claimsCountBadge: string = '';
    public editHistorys: any = [{ EventDateTime: "12/05/2023", EventType: "Created", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "01/05/2024", EventType: "Modified", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "Deleted", EventUser: "Roy.Munson", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "UnDeleted", EventUser: "Joe.Banks", EventCommentary: "This should not have been deleted.  Reactivating" }];

    public rolesTabFormControl: FormControl = new FormControl();

    @ViewChild('roleSort') roleSort: any;
    @ViewChild("claimSort") claimSort: any;

    public constructor(public appService: AppService,
                       public userService: UserService,
                       public roleService: RoleService,
                       private route: ActivatedRoute,
                       private location: Location/*
                       private dialogref: MatDialogRef<UserEditComponent>,
                       @Inject(MAT_DIALOG_DATA) data: any = null*/) {

        //if (data != null) {

        //    this.itemId = data;

        //} else {

        const parameters = route.params;

        if (parameters != null) {

            parameters.subscribe(p => {

                this.itemId = p["userId"];
            });
        }
        //}
    }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        this.roleService.search({ Name: "" }).subscribe((records: any) => {

            if (records != null) {

                this.availableRoles = records;
                this.loadItem();
            }
        });
    }

    //public ngAfterViewInit() {

    //    this.loadItem();
    //}

    public loadItem = (): void => {

        this.appService.IsAppBusy = true;

        this.appService.IsAppBusy = true;

        if (this.itemId == '0') {

            this.userService.getEmptyUser().subscribe((r: any) => {

                if (r != '') {

                    this.appService.IsAppBusy = false;

                    if (r instanceof HttpErrorResponse) {

                        this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                    } else {

                        this.itemData = r;
                    }
                }
            });

        } else {

            this.userService.getById(this.itemId).subscribe((user: any) => {

                if (user != '') {

                    this.appService.IsAppBusy = false;

                    if (user instanceof HttpErrorResponse) {

                        this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                    } else {

                        this.itemData = user;

                        //this.setRolesTableDataSource();
                        this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles);
                        this.resultsRolesMatTableDataSource.sort = this.roleSort;

                        //this.setClaimsTableDataSource();
                        this.resultsClaimsMatTableDataSource = new MatTableDataSource(this.itemData.Claims);
                        this.resultsClaimsMatTableDataSource.sort = this.claimSort;

                        // remove any available roles that are not available due to being assigned to this user
                        let assignedRoleIds: any = this.itemData.Roles.map((i: any) => i.Id);
                        this.availableRoles = this.availableRoles.filter((i: any) => !assignedRoleIds.includes(i.Id));

                        this.rolesResultsClass = this.itemData.Roles.length == 0 ? 'display-none' : 'display-block';
                        this.claimsResultsClass = this.itemData.Claims.length == 0 ? 'display-none' : 'display-block';
                    }
                }
            });
        }
    }

    public addRole = (): void => {

        this.itemData.Roles.push(this.selectedRole);
        this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles);

        const itemIndex = this.availableRoles.map((i: any) => i.Id).indexOf(this.selectedRole.Id, 0);

        if (itemIndex > -1) {

            this.availableRoles.splice(itemIndex, 1);
        }

        this.selectedRole = null;
        this.rolesResultsClass = this.itemData.Roles.length == 0 ? 'display-none' : 'display-block';
    }

    public editRole = (role: any): void => {

        this.appService.navigateTo('/role/' + role.Id);
    }

    public removeRole = (role: any): void => {

        const itemIndex = this.itemData.Roles.map((i: any) => i.Id).indexOf(role.Id, 0);

        if (itemIndex > -1) {

            this.itemData.Roles.splice(itemIndex, 1);
            this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles);
        }

        this.availableRoles.push(role);
        this.rolesResultsClass = this.itemData.Roles.length == 0 ? 'display-none' : 'display-block';
    }

    public save = (): void => {

        this.appService.IsAppBusy = true;

        let isCreatingNew = this.itemData.Id == '00000000-0000-0000-0000-000000000000';

        this.userService.save(this.itemData).subscribe((user: any) => {

            if (user != '') {

                this.appService.IsAppBusy = false;

                if (user instanceof HttpErrorResponse) {

                    this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                } else {

                    this.itemData = user;
                    this.itemId = this.itemData.Id;
                    this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles); 
                    this.resultsClaimsMatTableDataSource = new MatTableDataSource(this.itemData.Claims);

                    if (isCreatingNew) {

                        this.location.replaceState('/user/' + this.itemData.Id);
                    }
                }
            }
        });
    }

    public addClaim = (): void => {

        if (this.enteredClaimType.trim() == '' || this.enteredClaimValue.trim() == '') {

            this.appService.ShowModalDialog(ModalDialogSeverity.Warning, "Missing Data", "Unable to add claim due to missing information.  Both Type and Value must be filled in.", "OK", "");

        } else {

            this.itemData.Claims.push({ Type: this.enteredClaimType, Value: this.enteredClaimValue, ValueType: "", Issuer: "", OriginalIssuer: "" });
            this.enteredClaimType = '';
            this.enteredClaimValue = '';
            this.resultsClaimsMatTableDataSource = new MatTableDataSource(this.itemData.Claims);
        }

        this.claimsResultsClass = this.itemData.Claims.length == 0 ? 'display-none' : 'display-block';
    }

    public editClaim = (claim: any): void => {

        this.appService.showPendingFeatureMessage();
    }

    public removeClaim = (claim: any): void => {

        const itemIndex = this.itemData.Claims.indexOf(claim, 0);

        if (itemIndex > -1) {

            this.itemData.Claims.splice(itemIndex, 1);
            this.resultsClaimsMatTableDataSource = new MatTableDataSource(this.itemData.Claims);
        }

        this.claimsResultsClass = this.itemData.Claims.length == 0 ? 'display-none' : 'display-block';
    }

    public delete = (): void => {

        let proceedResponse = "PROCEED TO DELETE";

        this.appService.ShowModalDialog(ModalDialogSeverity.Warning,
                                        "Confirm Deletion",
                                        "Are you sure you want to delete user " + this.itemData.FirstName + " " + this.itemData.LastName + ".  If you are 100% sure, click PROCEEED TO DELETE, otherwise CANCEL",
                                        proceedResponse, 
                                        "CANCEL").subscribe((response) => {

            if (response == proceedResponse) {

                this.appService.IsAppBusy = true;

                this.userService.delete(this.itemData.Id).subscribe((r: any) => {

                    if (r != '') {

                        this.appService.IsAppBusy = false;

                        if (r instanceof HttpErrorResponse) {

                            this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                        } else {

                            this.appService.navigateTo('/usersearch');
                        }
                    }
                });
            }
        });
    }

    public showPasswordEditHint = (): void => {

        this.appService.ShowModalDialog(ModalDialogSeverity.Success, "Password Edit Hint", "Fill this in during the creation of new users.  Leave this blank during edits of existing users to preserve existing passwords.  Leaving this blank will not remove an existing password but rather preserve it", "OK", "");
    }
}
