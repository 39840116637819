<div class="login-container background-image" style="height: 80vh;display: flex;justify-content: center;align-items: center;">
  <div class="overlay">
    <div class="flex align-items-center justify-content-center">
      <div class="on-top mt-6 flex flex-column align-items-center" style="width: 22rem;">
        <svg
                class="logo"
                focusable="false"
                viewBox="0 0 407.8 55.7"
                aria-hidden="true"
              >
                <rect fill="white" x="60" y="0.1" width="42.6" height="10.9"></rect>
                <path
                  d="M60,55.7h42.6V44.8h-29V33.7h29v-11l-42.6,0C60.1,33.7,60,44.7,60,55.7z"
                  fill="white"
                ></path>
                <path
                  fill="white"
                  d="M158.6,0.2l-0.1-0.1L142,21.9L125.5,0.1l-0.1,0.1V0.1h-13.6v55.6h13.6V18c5.5,7.3,11.1,14.6,16.6,21.9l0,0l0,0 c5.6-7.3,11.1-14.6,16.6-22v37.8h13.6V0.1L158.6,0.2L158.6,0.2z"
                ></path>
                <rect
                  fill="white"
                  x="181.3"
                  y="0.1"
                  width="13.6"
                  height="55.6"
                ></rect>
                <polygon
                  fill="white"
                  points="204.2,11 223.1,11 223.1,55.7 236.7,55.7 236.7,11 263.5,11 263.5,55.7 277.1,55.7 277.1,11 295.7,11 295.7,0.1 204.2,0.1 	"
                ></polygon>
                <rect fill="white" x="305" y="0.1" width="42.6" height="10.9"></rect>
                <path
                  fill="white"
                  d="M305,55.7h42.6V44.8h-29V33.7h29v-11l-42.6,0C305,33.7,305,44.7,305,55.7z"
                ></path>
                <path
                  fill="white"
                  d="M397.7,1.7c-3-1.2-7-1.7-12-1.7h-28.7v11h24.7c3.3,0,5.6,0.2,6.8,0.5c6.6,1.7,5.5,8.9-0.2,10.7 c-1.3,0.4-3.4,0.6-6.3,0.6h-25v9.2v1.4v22.5h13.6v-22l13.1,0l7.2,22h15.9l-9.3-25.3C411.2,24.3,411.2,6.9,397.7,1.7z"
                ></path>
                <path
                  fill="white"
                  d="M40.8,1.7c-3-1.2-7-1.7-12-1.7H0v11h24.7c3.3,0,5.6,0.2,6.8,0.5c6.6,1.7,5.5,8.8-0.2,10.6 c-1.3,0.4-3.4,0.6-6.3,0.6h-7.3v11.1l9,0l7.2,22h15.9l-9.3-26.5C54.3,23.1,54.3,6.9,40.8,1.7z"
                ></path>
                <polygon
                  fill="white"
                  points="0,55.7 13.6,55.7 13.6,35.9 0,31.1 	"
                ></polygon>
                <path
                  fill="white"
                  d="M7.4,25.5L0,28.3L7.4,31l1.4,0.5l6.1,2.2v-5.5v-5.5L8.8,25L7.4,25.5z M12.9,28.2v3.1l-4.2-1.5h0l-4.2-1.5 l4.2-1.6h0l4.2-1.6V28.2z"
                ></path>
        </svg>

        <div class="mt-3 text-white font-bold text-4xl tools-word">TOOLS</div>
      </div>
    </div>

       
        
    

   <div class="flex justify-content-center align-items-center mt-5" >
   
    <p-card [style]="{ width: '360px' }">
      <ng-template pTemplate="header">
        <div class="font-semibold text-lg pt-5 pl-5">Login</div>
      </ng-template>
      <form>
        <mat-form-field class="px-3" style="width: 100%;">
            <mat-label>Email Address</mat-label>
            <input matInput #emailAddress maxlength="256" [(ngModel)]="credentials.Email" width="220" name="EmailAddress" />
        </mat-form-field>
        <br />
        <mat-form-field class="px-3" style="width: 100%;">
            <mat-label>Password</mat-label>
            <input matInput #password [type]="isPasswordHidden ? 'password' : 'text'" [(ngModel)]="credentials.Password" width="220" name="Password" required />
            <!--<mat-icon matSuffix (click)="isPasswordHidden = !isPasswordHidden">{{ isPasswordHidden ? 'visibility_off' : 'visibility' }}</mat-icon>-->
        </mat-form-field>
    </form>

    <div class="flex justify-content-center align-items-center">
      <button (click)="this.login()" class="pf-btn pf-btn-solid font-light w-7">
        LOGIN
      </button>

    </div>
    </p-card>
   
   </div>



  </div>



    
</div> 

