import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../utilities/date-helper';

@Pipe({
    name: 'dateAgo',
    pure: true
})

export class DateAgoPipe implements PipeTransform {

    transform(value: any, random: number): any {

        if (value) {

            let dateHelper = new DateHelper();

            return dateHelper.convertDateToDateAgoString(value);
        }

        return value;
    }
}
