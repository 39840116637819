import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { BreadcrumbItem } from '../../models/breadcrumb-item';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.css']
})

export class BreadcrumbsComponent implements OnInit {

    public navigationNode: any = null;
    public crumbs: BreadcrumbItem[] = [];

    public constructor(public appService: AppService) { }

    public ngOnInit(): void {

        this.appService.navigated.subscribe((navigationNode: any) => {

            this.crumbs = [];
            this.navigationNode = navigationNode;
            //const leaf: BreadcrumbItem = { Name: navigationNode.Name, RoutePath: navigationNode.RoutePath, IconName: navigationNode.IconName, IsLeaf: true };
            //this.crumbs.push(leaf);

            let p: any = this.appService.getNavigationNodeParentById(this.navigationNode.NavigationNodeId);

            if (p != null) {

                let crumb: BreadcrumbItem = { Name: p.DisplayText, RoutePath: p.RoutePath, IconName: p.MatIconName, IsLeaf: false };
                this.crumbs.push(crumb);

                while (p != null) {

                    p = this.appService.getNavigationNodeParentById(p.NavigationNodeId);

                    if (p != null) {

                        crumb = { Name: p.DisplayText, RoutePath: p.RoutePath, IconName: p.MatIconName, IsLeaf: false };
                        this.crumbs.push(crumb);
                    }
                }
            }

            this.crumbs = this.crumbs.reverse();
        });
    }
}
