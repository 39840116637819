import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    private _baseUrl: string = '';

    @Output() currentUserUpdated = new EventEmitter<boolean>();

    public constructor(public appService: AppService,
                       public http: HttpClient,
                       @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public getDefaultSearchParameters = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'User/GetDefaultSearchParameters';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public search = (userSearchParameters: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'User/Search';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, userSearchParameters, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getById = (userId: string): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'User/GetFullProfileById/' + userId;
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getEmptyUser = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'User/GetEmptyUser';
        const fullPath: string = this._baseUrl + apipath;
        //const userSession = localStorage.getItem("userSession");
        //const loggedUserJson = userSession.User;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public save = (user: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'User/Save';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, user, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

            if (user.id == this.appService.CurrentSession.AuthenticatedUser.User.id) {

                this.currentUserUpdated.emit(true);
            }

        }, error => {

            result.next(error);
        });

        return result;
    }

    public delete = (userId: string): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'User/Delete/' + userId;
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }
}
