import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ReportsService {

    public _baseUrl: string = '';

    public constructor(public appService: AppService,
                       public http: HttpClient,
                       @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public getBaseUrl = (): string => {

        return this._baseUrl;
    }

    public getDefaultSearchParameters = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Reports/GetDefaultSearchParameters';
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getNotificationReportData = (searchParameters: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'Reports/NotificationsBasicList';
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, searchParameters, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getMonthlyBillingReportFile = (searchParameters: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'billing/GetInvoicesByYearMonth?year=' + searchParameters.Year + '&month=' + searchParameters.Month;
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        options.responseType = "blob";

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }
}
