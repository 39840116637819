<mat-panel-title>
    <table class="nowrap-table">
        <tr>
            <td>
                <span *ngIf="_titleiconname == 'fa-thumbs-up'" class="material-symbols-outlined modal-title-icon icon-success-color">recommend</span>
                <span *ngIf="_titleiconname == 'fa-info-square'" class="material-symbols-outlined modal-title-icon icon-info-color">info</span>
                <span *ngIf="_titleiconname == 'fa-exclamation-square'" class="material-symbols-outlined modal-title-icon icon-warning-color">warning</span>
                <span *ngIf="_titleiconname == 'fa-thumbs-down'" class="material-symbols-outlined modal-title-icon icon-error-color">sentiment_very_dissatisfied</span>
                <!--<mat-icon *ngIf="_titleiconname == 'fa-thumbs-up'" class="material-icons modal-title-icon icon-success-color" fontSet="far" fontIcon="fa-thumbs-up" inline></mat-icon>
                <mat-icon *ngIf="_titleiconname == 'fa-info-square'" class="material-icons modal-title-icon icon-info-color" fontSet="far" fontIcon="fa-info-square" inline></mat-icon>
                <mat-icon *ngIf="_titleiconname == 'fa-exclamation-square'" class="material-icons modal-title-icon icon-warning-color" fontSet="far" fontIcon="fa-exclamation-square" inline></mat-icon>
                <mat-icon *ngIf="_titleiconname == 'fa-thumbs-down'" class="material-icons modal-title-icon icon-error-color" fontSet="far" fontIcon="fa-thumbs-down" inline></mat-icon>-->
            </td>
            <td>
                &nbsp;
            </td>
            <td>
                <h2>{{ modaldialogdata.Title }}</h2>
            </td>
        </tr>
    </table>
</mat-panel-title>
<br />
<mat-divider></mat-divider>
<br />
<div>
    {{ modaldialogdata.Message }}
</div>
<br />
<mat-divider></mat-divider>
<br />
<div>
    <table class="nowrap-table">
        <tr>
            <td>
                <button mat-raised-button color="primary" (click)="this.confirm()">{{modaldialogdata.AcceptButtonText}}</button>
            </td>
            <td>
                &nbsp;
            </td>
            <td>
                <button *ngIf="modaldialogdata.CancelButtonText != ''" mat-raised-button color="basic" (click)="this.cancel()">{{ modaldialogdata.CancelButtonText}}</button>
            </td>
        </tr>
    </table>
</div>
