import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { NavigationNodeService } from '../../services/navigation-node/navigation-node.service';
import { RoleService } from '../../services/role/role.service';

@Component({
    selector: 'app-navigation-node-edit',
    templateUrl: './navigation-node-edit.component.html',
    styleUrls: ['./navigation-node-edit.component.css']
})

export class NavigationNodeEditComponent implements OnInit {

    public itemId: string = '-1';
    public itemData: any = null;
    public parentNavigationNodeId: number = -1; // this is filled in from parameters upon create of new node 
    public resultsRolesMatTableDataSource: any = null;
    public displayedRolesColumns: string[] = ['Avatar', 'RoleName', 'RemoveRole'];
    public availableRoles: any = null;
    public selectedRole: any = null;
    public rolesResultsClass: string = 'display-none';
    public editHistorys: any = [{ EventDateTime: "12/05/2023", EventType: "Created", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "01/05/2024", EventType: "Modified", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "Deleted", EventUser: "Roy.Munson", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "UnDeleted", EventUser: "Joe.Banks", EventCommentary: "This should not have been deleted.  Reactivating" }];

    public rolesTabFormControl: FormControl = new FormControl();

    @ViewChild('roleSort') roleSort: any;

    public constructor(public appService: AppService,
                       private navigationService: NavigationNodeService,
                       private roleService: RoleService,
                       private route: ActivatedRoute,
                       private location: Location) {

        const parameters = route.params;

        if (parameters != null) {

            parameters.subscribe(p => {

                const isInitialLoad: boolean = this.itemId == '-1';

                this.itemId = p['navigationNodeId'];

                if (p['parentNavigationNodeId'] != null) {

                    this.parentNavigationNodeId = parseInt(p['parentNavigationNodeId']);
                }

                if (!isInitialLoad) { // let ngoninit handle the first load, then if the id change, reload 

                    this.loadItem();
                }
            });
        }
    }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        this.roleService.search({ Name: "" }).subscribe((records: any) => {

            if (records != null) {

                this.availableRoles = records;
                this.loadItem();
                this.appService.IsAppBusy = false;
            }
        });
    }

    private loadItem = (): void => {

        if (this.itemId == '0' && this.parentNavigationNodeId != null && this.parentNavigationNodeId > 0) {

            this.navigationService.getNewNode(this.parentNavigationNodeId).subscribe((node: any) => {

                if (node != null) {

                    this.itemData = node;

                    this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles);
                    this.resultsRolesMatTableDataSource.sort = this.roleSort;

                    // remove any available roles that are not available due to being assigned to this user
                    let assignedRoleIds: any = this.itemData.Roles.map((i: any) => i.RoleName);
                    this.availableRoles = this.availableRoles.filter((i: any) => !assignedRoleIds.includes(i.Id));

                    this.rolesResultsClass = this.itemData.Roles.length == 0 ? 'display-none' : 'display-block';
                }
            });

        } else {

            this.navigationService.getById(parseInt(this.itemId)).subscribe((node: any) => {

                if (node != null) {

                    this.itemData = node;

                    this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles);
                    this.resultsRolesMatTableDataSource.sort = this.roleSort;

                    // remove any available roles that are not available due to being assigned to this user
                    let assignedRoleIds: any = this.itemData.Roles.map((i: any) => i.RoleName);
                    this.availableRoles = this.availableRoles.filter((i: any) => !assignedRoleIds.includes(i.Id));

                    this.rolesResultsClass = this.itemData.Roles.length == 0 ? 'display-none' : 'display-block';
                }
            });
        }
    }

    public addRole = (): void => {

        this.itemData.Roles.push({ CreatedBy: this.appService.CurrentSession.AuthenticatedUser.User.Email, CreatedOn: new Date().toISOString(), DeletedBy: null, DeletedOn: null, ModifiedBy: null, ModifiedOn: null, NavigationNodeId: this.itemData.NavigationNodeId, NavigationNodeRoleId: -1, RoleName: this.selectedRole.Name });
        this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles);

        const itemIndex = this.availableRoles.map((i: any) => i.Name).indexOf(this.selectedRole.Name, 0);

        if (itemIndex > -1) {

            this.availableRoles.splice(itemIndex, 1);
        }

        this.selectedRole = null;
        this.rolesResultsClass = this.itemData.Roles.length == 0 ? 'display-none' : 'display-block';
    }

    public editRole = (role: any): void => {

        var roles = this.availableRoles.filter((i: any) => i.Name == role.RoleName);

        if (roles != null && roles.length > 0) {

            this.appService.navigateTo('/role/' + roles[0].Id);
        }
    }

    public removeRole = (role: any): void => {

        const itemIndex = this.itemData.Roles.map((i: any) => i.Name).indexOf(role.Name, 0);

        if (itemIndex > -1) {

            this.itemData.Roles.splice(itemIndex, 1);
            this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles);
        }

        this.availableRoles.push({ ConcurrencyStamp: "", Id: "????????-????-????-????-????????????", Name: role.RoleName, NormalizedName: role.RoleName.toUpperCase() });
        this.rolesResultsClass = this.itemData.Roles.length == 0 ? 'display-none' : 'display-block';
    }

    public save = (): void => {

        this.appService.IsAppBusy = true;

        let isCreatingNew = this.itemData.NavigationNodeId < 0;

        if (isCreatingNew) {

            this.itemData.CreatedBy = this.appService.CurrentSession.AuthenticatedUser.User.Email;
            this.itemData.CreatedOn = new Date().toISOString();
        }

        if (this.itemData.ParentNavigationNodeId != null && this.itemData.ParentNavigationNodeId.toString().trim() == "") {
            this.itemData.ParentNavigationNodeId = null;
        }

        this.navigationService.save(this.itemData).subscribe((node: any) => {

            if (node != '') {

                this.appService.IsAppBusy = false;

                if (node instanceof HttpErrorResponse) {

                    this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                } else {

                    this.itemData = node;
                    this.itemId = this.itemData.NavigationNodeId
                    this.resultsRolesMatTableDataSource = new MatTableDataSource(this.itemData.Roles);

                    if (isCreatingNew) {

                        this.location.replaceState('/navigationnode/' + this.itemData.NavigationNodeId.toString());
                    }
                }
            }
        });
    }
}
