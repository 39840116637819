import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../utilities/date-helper';

@Pipe({
    name: 'dateUtcToLocal',
    pure: true
})

export class DateUtcToLocalPipe implements PipeTransform {

    transform(value: any, random: number): any {

        if (value) {

            let candidateUtc = new Date(value);
            let dateHelper = new DateHelper();
            let candidateLocal = dateHelper.convertUTCDateToLocalDate(candidateUtc);

            return candidateLocal;
        }

        return value;
    }
}
