<div *ngIf="appService.CurrentSession != null" style="padding-right: 22px;">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User menu">
        <span class="avatar" style="background-color:{{avatarBackgroundColor}};color:{{avatarTextColor}}">{{userInitials}}</span>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="showProfile()">
            <mat-icon class="material-symbols-outlined">face</mat-icon>
            <span>{{this.appService.CurrentSession.AuthenticatedUser.User.FirstName}} {{this.appService.CurrentSession.AuthenticatedUser.User.LastName}}</span>
        </button>
        <button mat-menu-item (click)="showSettings()">
            <mat-icon>blur_on</mat-icon>
            <span>Settings</span>
        </button>
        <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</div>
