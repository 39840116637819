<div class="FullHeightContentContent" style="height: 70vh;display: flex;justify-content: center;align-items: center;">

    <!--<h4>USER AND ROLE MANAGEMENT</h4>-->
    <div class="flex flex-column">
        <br />
        <img src="/assets/welcome.png" style="width:296px;" /> 
        <br />
    
        <div class="flex justify-content-center align-items-center" style="color: slategrey; text-align: center;">
            <button mat-button (click)="appService.navigateTo('/userprofile')" style="text-align:center;">
                <span class="material-symbols-outlined">
                    face
                </span>
            </button>
        </div>
    </div>
</div>
