import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AppService } from '../../app.service';
import { ReportsService } from '../../services/reports/reports.service';
import { saveAs } from 'file-saver';
import { forkJoin } from 'rxjs';
import { ReferenceDataService } from '../../services/referencedata/reference-data.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';

@Component({
  selector: 'app-notification-report',
  templateUrl: './notification-report.component.html',
  styleUrls: ['./notification-report.component.css']
})

export class NotificationReportComponent implements OnInit {

    public creditorsFormControl: FormControl = new FormControl();
    public statusesFormControl:FormControl = new FormControl();
    public typesFormControl: FormControl = new FormControl();
    public fromDateFormControl: FormControl = new FormControl();
    public toDateFormControl: FormControl = new FormControl();
    public creditorsList: any = null;
    public statusesList: any = null;
    public typesList: any = null;
    public reportData: any = [];
    public isReportPendingRetrieval: boolean = false;
    public searchParameters: any = null;

    public constructor(public appService: AppService,
                       public reportsService: ReportsService,
                       public referenceDataService: ReferenceDataService) {
    }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        forkJoin(this.reportsService.getDefaultSearchParameters(),
                 this.referenceDataService.getCreditors(),
                 this.referenceDataService.getNotificationStatuses(),
                 this.referenceDataService.getNotificationTypes()).subscribe(([searchParameters,
                                                                               creditors,
                                                                               statuses,
                                                                               types]) => {

            var allowedCreditors = this.appService.CurrentSession.AuthenticatedUser.User.Claims.filter((i: any) => i.Type.toLowerCase() == "creditorid").map((i: any) => i.Value);
            var creditorsFiltered = creditors.filter((i: any) => allowedCreditors.indexOf(i.CreditorId) > - 1);
            this.searchParameters = searchParameters;
            this.creditorsList = creditorsFiltered;
            this.statusesList = statuses;
            this.typesList = types;

            this.appService.IsAppBusy = false;
        });
    }

    public runReport = () => {

        if (this.searchParameters.CreditorId == '') {

            this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Missing Input", "You didn't enter an account contact id.  Enter an account contact id in thre format creditor/{creditorid}/accountcontact/{integrationlinkid}.  So for example creditor/1/accountcontact/abc123.", "GOTCHA", "");

        } else {

            const proceedResponse = "RUN IT !";

            this.appService.ShowModalDialog(ModalDialogSeverity.Info,
                                            "Confirm Criteria",
                                            "Please note that this report pulls data from Remitter's Data Lake and may take a long time to run depending on the data range and filters selected above.  The report is completed if and when a button entitled 'Retrieve Report' appears on this page", 
                                            proceedResponse,
                                            "CANCEL").subscribe((response) => {

                if (response == proceedResponse) {

                    this.appService.IsAppBusy = true;

                    this.reportsService.getNotificationReportData(this.searchParameters).subscribe((records: any) => {

                        if (records != '') {

                            this.appService.IsAppBusy = false;

                            if (records.length == 1 && records[0].InvoiceNumber == null) { // TODO: need a better way to detect no results

                                this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Empty Report", "No data was returned for the criteria.  Check the creditor id, statuses and possibly expand the date range", "OK", "");

                            } else {

                                this.reportData = records;
                                this.isReportPendingRetrieval = true;
                            }
                        }
                    });
                }
            });
        }
    }

    public getFile = () => {

        const replacer = (key: any, value: null) => value === null ? '' : value;
        const header = Object.keys(this.reportData[0]);

        let csv = this.reportData.map((row: { [x: string]: any; }) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var blob = new Blob([csvArray], { type: 'text/csv' })

        let now = new Date();
        let suffix = '';

        try { // to make a nice file name but be careful because we don't know what funky characters might be in the name 

            suffix = this.creditorsList.find((i: { CreditorId: string; CompanyName: string }) => { return i.CreditorId == this.searchParameters.CreditorId }).CompanyName;
            suffix += "_" + this.searchParameters.FromDate.getFullYear() + "-" + (this.searchParameters.FromDate.getMonth() + 1) + "-" + this.searchParameters.FromDate.getDate();
            suffix += "_" + this.searchParameters.ToDate.getFullYear() + "-" + (this.searchParameters.ToDate.getMonth() + 1) + "-" + this.searchParameters.ToDate.getDate();
            suffix += "_" + this.typesList.filter((i: { Id: any; }) => this.searchParameters.NotificationTypeIds.includes(i.Id)).map((i: { Id: number, Name: string; }) => i.Name).join('-');
            suffix += "_" + this.statusesList.filter((i: { Id: any; }) => this.searchParameters.NotificationStatusIds.includes(i.Id)).map((i: { Id: number, Name: string; }) => i.Name).join('-');

        } catch (e) {

            suffix += "_???_";
        }

        let timestamp = now.getTime(); // unique-ify incase user runs same report multiple times 

        saveAs(blob, "Notifications_Report_" + suffix + "_" + timestamp + ".csv");

        this.isReportPendingRetrieval = false;
    }
}
