import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ContactService {

    private _baseUrl: string = '';

    public constructor(public appService: AppService,
                       public http: HttpClient,
                       @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public getDefaultSearchParameters = (): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'Contact/GetDefaultSearchParameters';
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public search = (contactSearchParameters: any): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'Contact/Search';
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, contactSearchParameters, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getSqlAccountContact = (accountContactId: string): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'Contact/GetContactById';
        const fullPath: string = this._baseUrl + apipath + '?accountContactId=' + accountContactId;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);

        }, error => {

            result.next(error);
        });

        return result;
    }

    public saveSqlAccountContact = (sqlAccountContact: any): Observable<boolean> => {

        let result: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
        const apiPath: string = 'Contact/SaveContact';
        const fullPath: string = this._baseUrl + apiPath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<boolean>(fullPath, sqlAccountContact, options).subscribe((r:any) => {

            result.next(r);

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getSampleSqlQuery = (accountContactId: string): string => {

        let result: string = "DECLARE @AccountContactId VARCHAR(100)";

        result += "\r\nSET @AccountContactId = '" + accountContactId + "'";
        result += "\r\nSELECT * FROM Accounts.AccountContact WHERE AccountContactId = @AccountContactId";
        result += "\r\nSELECT * FROM Accounts.ContactAddress WHERE AccountContactId = @AccountContactId";
        result += "\r\nSELECT * FROM Accounts.ContactPhone WHERE AccountContactId = @AccountContactId";
        result += "\r\nSELECT * FROM Payments.PaymentInstrument WHERE AccountContactId = @ACcountContactId";

        return result;
    }
}
