<mat-card>
    <mat-card-header>
        <div mat-card-avatar class="mat-card-header-text">
            <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                person
            </div>
        </div>
        <mat-card-title>Search Users</mat-card-title>
        <mat-card-subtitle>Search and edit users</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <form (keyup.enter)="search()">
            <div>
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Email Address</mat-label>
                    <span matPrefix style="color:dimgrey;">
                        <span class="material-symbols-outlined" matTooltip="Leading search wildcard" style="font-size:10pt;">
                            star
                        </span>
                    </span>
                    <input matInput #emailAddress size="1" (keyup)="appService.syncWildcardSuffixPosition($event, false)" (focus)="appService.syncWildcardSuffixPosition($event, false)" (blur)="appService.syncWildcardSuffixPosition($event, true)" class="input-prefix-suffix" maxlength="256" [(ngModel)]="searchParameters.EmailAddress" width="220" name="EmailAddress" />
                    <span matSuffix class="wildcard-suffix" style="color:dimgrey;">
                        <span class="material-symbols-outlined" matTooltip="Trailing search wildcard" style="font-size:10pt;position:relative;left:0px;">
                            star
                        </span>
                    </span>
                </mat-form-field>
            </div>
            <div class="{{advancedSearchClass}}">
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>First Name</mat-label>
                    <span matPrefix style="color:dimgrey;">
                        <span class="material-symbols-outlined" matTooltip="Leading search wildcard" style="font-size:10pt;">
                            star
                        </span>
                    </span>
                    <input matInput #firstName maxlength="256" [(ngModel)]="searchParameters.FirstName" (keyup)="appService.syncWildcardSuffixPosition($event, false)" (focus)="appService.syncWildcardSuffixPosition($event, false)" (blur)="appService.syncWildcardSuffixPosition($event, true)" width="220" name="FirstName" />
                    <span matSuffix class="wildcard-suffix" style="color: dimgrey;">
                        <span class="material-symbols-outlined" matTooltip="Trailing search wildcard" style="font-size:10pt;position:relative;left:0px;">
                            star
                        </span>
                    </span>
                </mat-form-field>
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Last Name</mat-label>
                    <span matPrefix style="color: dimgrey;">
                        <span class="material-symbols-outlined" matTooltip="Leading search wildcard" style="font-size:10pt;">
                            star
                        </span>
                    </span>
                    <input matInput #lastName maxlength="256" [(ngModel)]="searchParameters.LastName" (keyup)="appService.syncWildcardSuffixPosition($event, false)" (focus)="appService.syncWildcardSuffixPosition($event, false)" (blur)="appService.syncWildcardSuffixPosition($event, true)" width="220" name="LastName" />
                    <span matSuffix class="wildcard-suffix" style="color: dimgrey;">
                        <span class="material-symbols-outlined" matTooltip="Trailing search wildcard" style="font-size:10pt;position:relative;left:0px;">
                            star
                        </span>
                    </span>
                </mat-form-field>
            </div>
            <button mat-button (click)="search()">
                <span class="material-symbols-outlined">
                    search
                </span>
                SEARCH
            </button>
            <button mat-button (click)="toggleAdvancedSearch()">
                <span class="material-symbols-outlined">
                    tune
                </span>
                ADVANCED
            </button>
            <button mat-button (click)="create()">
                <span class="material-symbols-outlined">
                    add
                </span>
                ADD NEW
            </button>
        </form>
    </mat-card-content>
    <mat-card-footer>
    </mat-card-footer>
</mat-card>
<br />
<mat-card class="{{resultsClass}}">
    <mat-card-title>
        Results
    </mat-card-title>
    <mat-card-content>
        <table mat-table matSort [dataSource]="resultsMatTableDataSource" class="mat-elevation-z8">
            <!--style="height:calc(100vh - 480px);background-color:lime;"-->
            <ng-container matColumnDef="Avatar">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)">
                    <span class="material-symbols-outlined" style="font-size: 18pt;">
                        person
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="Username">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> UserName </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.Username}} </td>
            </ng-container>
            <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FirstName </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.FirstName}} </td>
            </ng-container>
            <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> LastName </th>
                <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.LastName}} </td>
            </ng-container>
            <ng-container matColumnDef="OpenModal">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item" (click)="delete(item)">
                    <span class="material-symbols-outlined" style="font-size: 18pt;">
                        delete_forever
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
    <mat-card-footer>
        <mat-paginator *ngIf="resultsRecordCount > 0" [pageSizeOptions]="[20, 50, 100]" aria-label="Select page of items"></mat-paginator>
    </mat-card-footer>
</mat-card>
