<div class="text-2xl font-bold bottom-padded">
    File Mapping Search
</div>
<br />
<div class="flex justify-content-between mt-5 bottom-padded">
    <div class="flex flex-row gap-3">
        <p-floatLabel>
            <p-autoComplete [(ngModel)]="selectedCreditor" 
                            [suggestions]="filteredItems"  
                            (completeMethod)="filterItems($event)" 
                            field="LongNameWithId" 
                            [dropdown]="true" 
                            placeholder="Select Creditor" 
                            id="creditors">
               <ng-template let-creditor pTemplate="item">
                <div class="flex align-items-center justify-content-between flex-1">
                  <div>{{creditor.CompanyName}}</div>
                  <div>{{creditor.CreditorId.replace('creditor/','')}}</div>
                </div>
            </ng-template>
         </p-autoComplete>
            <!-- 
             <p-dropdown id="creditors" [options]="creditors"
                        [(ngModel)]="selectedCreditor"
                        optionLabel="LongNameWithId"
                        placeholder="Select Creditor"> 
            </p-dropdown>
            -->
            <label for="creditors">Creditor</label>
        </p-floatLabel>
        <p-floatLabel>
            <input pInputText id="mappingType" [(ngModel)]="searchMappingTypeExpression" placeholder="Type name expression" />
            <label for="mappingType">Mapping Type</label>
        </p-floatLabel>
        <p-button (click)="search()" label="Search Mappings" icon="pi pi-search" severity="info"></p-button>
        <p-button (click)="create()" label="New Mapping" icon="pi pi-plus" severity="success"></p-button>
    </div>
</div>
<div class="mt-3">
    <p-table [value]="searchResult" [paginator]="true"
             [rows]="20"
             [tableStyle]="{ 'min-width': '50rem' }"
             [rowsPerPageOptions]="[20, 50, 100]">
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>Creditor ID</th>
                <th>Creditor Company Name</th>
                <th>File Mapping Type Name</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-mappingSearchResult>
            <tr>
                <td (click)="edit(mappingSearchResult)">
                    <span title="File Mapping ID: {{ mappingSearchResult.DataFileMappingId }}" class="pi pi-map icon"></span>
                </td>
                <td (click)="edit(mappingSearchResult)">{{ mappingSearchResult.CreditorId }}</td>
                <td (click)="edit(mappingSearchResult)">{{ mappingSearchResult.CreditorCompanyName }}</td>
                <td (click)="edit(mappingSearchResult)">{{ mappingSearchResult.FileMappingTypeName }}</td>
                <td>
                    <p-button (click)="deleteMapping(mappingSearchResult)" label="DELETE" icon="pi pi-trash" severity="warning"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
