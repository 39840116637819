import { Component } from '@angular/core';
import { EtlIngestionService } from '../../services/etl-ingestion/etl-ingestion.service';
import { AppService } from '../../app.service';
import { NotificationSettingsService } from '../../services/notification-settings/notification-settings.service';
import { catchError, finalize, of, tap } from 'rxjs';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrl: './notification-settings.component.css'
})
export class NotificationSettingsComponent {
  public creditors: any | null = null;
  public selectedCreditor: any | null = null;
  public filteredCreditors: any[] = [];
  public sms: any | null;
  public email: any | null;

  public smsPlatformTypeList: any | null = null;
  public filteredSmsPlatformTypeList: any | null = null;

  public emailPlatformTypeList: any | null = null;
  public filteredEmailPlatformTypeList: any | null = null;
  
  public constructor(
    private etlIngestionService: EtlIngestionService, 
    private notificationSettingsService: NotificationSettingsService,
    public appService: AppService
  ){}

  ngOnInit() {    
    const loggedUserSession = localStorage.getItem('userSession');
    if (loggedUserSession) {
      const loggedUserToken = JSON.parse(loggedUserSession).AuthenticatedUser.Token;
      console.log('bearer token:',loggedUserToken );
    }

    this.etlIngestionService.getCreditors().subscribe((res:any) => {
     if(!!res && !!res.Creditors && Array.isArray(res.Creditors) && res.Creditors.length > 0){
       this.creditors = res.Creditors;
     }   
    });

    this.notificationSettingsService.getSmsPlatformTypeList().subscribe((res:any) => {
      if(!!res && Array.isArray(res) && res.length > 0){
        this.smsPlatformTypeList = res;
      }   
     });

     this.notificationSettingsService.getSmsPlatformTypeList().subscribe((res:any) => {
      if(!!res && Array.isArray(res) && res.length > 0){
        this.emailPlatformTypeList = res;
      }   
     });
 }

  filterCreditors(event: any) {
    let filteredItems: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.creditors as any[]).length; i++) {
        let item = (this.creditors as any[])[i];
        if (item.CreditorId.indexOf(query) !== -1
        || item.CompanyName.toLowerCase().indexOf(query.toLowerCase()) !== -1
      ) {
            filteredItems.push(item);
        }
    }

    this.filteredCreditors = filteredItems;
  }

  onSelectCreditor(event:any){    
   this.appService.pageSpinnerVisible = true;
   this.notificationSettingsService.getNotificationSettings('creditor/'+this.selectedCreditor.CreditorId).subscribe((res:any) => {
    this.appService.pageSpinnerVisible = false;

    this.sms = this.smsPlatformTypeList.find((i:any) => i.name === res.sms);
    this.email = this.emailPlatformTypeList.find((i:any) => i.name === res.email);
   });
  }

  filterSmsPlatformType(event: any) {
    let filteredItems: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.smsPlatformTypeList as any[]).length; i++) {
        let item = (this.smsPlatformTypeList as any[])[i];
        if (item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
            filteredItems.push(item);
        }
    }

    this.filteredSmsPlatformTypeList = filteredItems;
  }

  filterEmailPlatformType(event: any) {
    let filteredItems: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.emailPlatformTypeList as any[]).length; i++) {
        let item = (this.emailPlatformTypeList as any[])[i];
        if (item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
            filteredItems.push(item);
        }
    }

    this.filteredEmailPlatformTypeList = filteredItems;
  }

  validateInputs() : boolean{
    if(!this.sms || !this.email){
      this.appService.primeDialogVisible = true;
      this.appService.primeDialogMessage = 'Please provide all inputs';
      this.appService.primeDialogHeader  = 'Validation';
      return false;
    }
    return true;
  }

  onClickSubmit(){
    const isValid =  this.validateInputs();
    this.appService.pageSpinnerVisible = true;
    if(isValid){
      this.notificationSettingsService.putNotificationSettings(this.sms.name, this.email.name, 'creditor/'+this.selectedCreditor.CreditorId)
      .pipe(
        tap(() => {
          this.appService.primeDialogHeader  = 'Success';
          this.appService.primeDialogMessage = 'Payment Gateway Config has been successfully updated.';
        }),
        catchError(error => {
          this.appService.primeDialogHeader  = 'Error';
          this.appService.primeDialogMessage = 'There seems to be an error saving the tag.';
          return of(null); 
        }),
        finalize(() => {
          this.appService.primeDialogVisible = true;
          this.appService.pageSpinnerVisible = false;
        })
      )
      .subscribe();
    }
  }
}
