import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class InvoiceService {

    private _baseUrl: string = '';

    public constructor(public appService: AppService,
                       public http: HttpClient,
                       @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public getDefaultSearchParameters = (): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'Invoice/GetDefaultSearchParameters';
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public search = (invoiceSearchParameters: any): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'Invoice/Search';
        const fullPath: string = this._baseUrl + apipath
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, invoiceSearchParameters, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getSqlAccountInvoice = (accountInvoiceId: string): Observable<any> => {

        let result: BehaviorSubject<string> = new BehaviorSubject<string>('');
        const apipath: string = 'Invoice/GetInvoiceById';
        const fullPath: string = this._baseUrl + apipath + '?accountInvoiceId=' + accountInvoiceId;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);

        }, error => {

            result.next(error);
        });

        return result;
    }

    public saveSqlAccountInvoice = (sqlAccountInvoice: any): Observable<boolean> => {

        let result: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
        const apiPath: string = 'Invoice/SaveInvoice';
        const fullPath: string = this._baseUrl + apiPath;

        if (this.appService.CurrentSession) {

            const options = this.appService.getHttpHeaderOptions();

            this.http.post<boolean>(fullPath, sqlAccountInvoice, options).subscribe((r:any) => {

                result.next(r);

            }, error => {

                result.next(error);
            });
        }

        return result;
    }

    public getSampleSqlQuery = (accountInvoiceId: string): string => {

        let result: string = "DECLARE @AccountInvoiceId VARCHAR(100)";

        result += "\r\nSET @AccountInvoiceId = '" + accountInvoiceId + "'";
        result += "\r\n\r\nSELECT * FROM Accounts.AccountInvoice WHERE AccountInvoiceId = @AccountInvoiceId";
        result += "\r\nSELECT * FROM Accounts.InvoiceChallenge WHERE AccountInvoiceId = @AccountInvoiceId";
        result += "\r\nSELECT * FROM Accounts.AccountInvoiceFee WHERE AccountInvoiceId = @AccountInvoiceId";
        result += "\r\nSELECT * FROM Payments.RecurringPayment WHERE AccountInvoiceId = @AccountInvoiceId";
        result += "\r\nSELECT * FROM Payments.RecurringPaymentFee WHERE RecurringPaymentId IN (SELECT RecurringPaymentId FROM Payments.RecurringPayment WHERE AccountInvoiceId = @AccountInvoiceId)";
        result += "\r\nSELECT * FROM Payments.RecurringPaymentTransactionView WHERE RecurringPaymentId IN (SELECT RecurringPaymentId FROM Payments.RecurringPayment WHERE AccountInvoiceId = @AccountInvoiceId)";
        result += "\r\n/*following queries serve as source to the recurringpaymenttransactionview and provided for informational purposes*/";
        result += "\r\nSELECT * FROM Payments.AccountTransaction WHERE AccountPaymentId = @AccountInvoiceId + '/payment'";
        result += "\r\nSELECT * FROM Payments.RecurringPaymentDue WHERE RecurringPaymentId IN (SELECT RecurringPaymentId FROM Payments.RecurringPayment WHERE AccountInvoiceId = @AccountInvoiceId)";

        return result;
    }
}
