import { Component } from '@angular/core';
import { EtlIngestionService } from '../../services/etl-ingestion/etl-ingestion.service';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-etl-ingestion-result',
  templateUrl: './etl-ingestion-result.component.html',
  styleUrl: './etl-ingestion-result.component.css',
})

export class EtlIngestionResultComponent {

    etlIngestionResults!: any[]; 
    creditors: any[] | undefined;
    fileName: string = '';
    selectedCreditors: any | undefined;
    fromDate: any = new Date();
    toDate: any = new Date();
    minDate: any = new Date(this.toDate.getTime() - (13 * 24 * 60 * 60 * 1000));

  public constructor(private etlIngestionService: EtlIngestionService, private appService: AppService) { }

    ngOnInit() {

     this.etlIngestionService.getCreditors().subscribe((res:any) => {
      if(res.Creditors && Array.isArray(res.Creditors) && res.Creditors.length > 0){
        this.creditors = res.Creditors;
      }   
     });
  }

  search(){
    let creditorInputs;

    if(this.selectedCreditors && Array.isArray(this.selectedCreditors) && this.selectedCreditors.length > 0){
      creditorInputs =  this.selectedCreditors.map((i: any) => i.CreditorId).join(',');
    }

    this.etlIngestionService.search(creditorInputs, this.fromDate, this.toDate).subscribe((res: any) => {
      this.etlIngestionResults = res;
    });
  }
}
