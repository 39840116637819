<div class="FullHeightContentContainer">
    <div class="FullHeightContenttHeader">
        <table style="width:100%;">
            <tr>
                <td rowspan="2" style="padding-left:12px;width:1px;">
                    <span class="material-symbols-outlined" style="font-size: 32pt;">
                        notifications_active
                    </span>
                </td>
                <td style="padding-left:38px;">
                    <mat-card-title>Archived Notifications Report</mat-card-title>
                </td>
            </tr>
            <tr>
                <td style="padding-left:42px;">
                    <mat-card-subtitle>Generate Notifications from the Remitter Data Lake.</mat-card-subtitle>
                </td>
            </tr>
        </table>
    </div>
    <div class="FullHeightContentContent">
        <form *ngIf="searchParameters" (keyup.enter)="runReport()">
            <mat-form-field class="" style="width:320px; padding-left: 12px;">
                <mat-label>CreditorId</mat-label>
                <mat-select [formControl]="creditorsFormControl" [(ngModel)]="searchParameters.CreditorId">
                    <mat-option *ngFor="let creditor of creditorsList" [value]="creditor.CreditorId">{{creditor.CompanyName}} ({{creditor.CreditorId}})</mat-option>
                </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field class="" style="width:320px; padding-left: 12px;">
                <mat-label>Notification Statuses</mat-label>
                <mat-select [formControl]="statusesFormControl" [(ngModel)]="searchParameters.NotificationStatusIds" multiple>
                    <mat-option *ngFor="let status of statusesList" [value]="status.Id">{{status.Name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field class="" style="width:320px; padding-left: 12px;">
                <mat-label>Notification Types</mat-label>
                <mat-select [formControl]="typesFormControl" [(ngModel)]="searchParameters.NotificationTypeIds" multiple>
                    <mat-option *ngFor="let type of typesList" [value]="type.Id">{{type.Name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field class="" style="width:160px;padding-left: 12px;">
                <mat-label>From Date</mat-label>
                <input matInput [(ngModel)]="searchParameters.FromDate" [formControl]="fromDateFormControl" [matDatepicker]="pickerFrom" placeholder="">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="" style="width:160px;padding-left: 12px;">
                <mat-label>To Date</mat-label>
                <input matInput [(ngModel)]="searchParameters.ToDate" [formControl]="toDateFormControl" [matDatepicker]="pickerTo" placeholder="">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
            <br />
            <mat-form-field class="" style="width: 320px; padding-left: 12px;">
                <mat-label>Integration Link ID</mat-label>
                <input matInput #integrationLinkId maxlength="256" [(ngModel)]="searchParameters.IntegrationLinkId" width="220" name="IntegrationLinkId" />
            </mat-form-field>
        </form>
        <span class="ActionButtons">
            <button mat-button (click)="runReport()">
                <span class="material-symbols-outlined">
                    dynamic_form
                </span>
                RUN REPORT
            </button>
            <button *ngIf="isReportPendingRetrieval" mat-button (click)="getFile()">
                <span class="material-symbols-outlined">
                    csv
                </span>
                RETRIEVE FILE
            </button>
        </span>
    </div>
    <div class="FullHeightContentFooter">
    </div>
</div>
