<div class="FullHeightContentContainer">
    <div class="FullHeightContenttHeader">
        <table style="width:100%;">
            <tr *ngIf="navigationNode">
                <td rowspan="2" style="padding-left:12px;width:1px;">
                    <span class="material-symbols-outlined" style="font-size: 32pt;">
                        {{navigationNode.MatIconName}}
                    </span>
                </td>
                <td style="padding-left:38px;">
                    <mat-card-title>{{navigationNode.DisplayText}}</mat-card-title>
                </td>
            </tr>
            <tr>
                <td style="padding-left:42px;">
                    <mat-card-subtitle>Folder Contents</mat-card-subtitle>
                </td>
            </tr>
        </table>
    </div>
    <div class="FullHeightContentContent">
        <mat-list *ngIf="navigationNode && navigationNode.NavigationNodes.length > 0" role="list">
            <mat-list-item role="listitem" *ngFor="let navigationNode of navigationNode.NavigationNodes; let i = index">
                <button mat-button (click)="appService.navigateTo(navigationNode.RoutePath)">
                    <span class="material-symbols-outlined">
                        {{navigationNode.MatIconName}}
                    </span>
                    {{navigationNode.DisplayText}}
                </button>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="FullHeightContentFooter">

    </div>
</div>
