<mat-drawer-container class="example-container" autosize>
    <mat-drawer #historyDrawer class="example-sidenav" mode="side" position="end">
        <mat-card>
            <mat-card-header>
                <div mat-card-avatar class="mat-card-header-text">
                    <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                        history
                    </div>
                </div>
                <mat-card-title>History</mat-card-title>
                <mat-card-subtitle>Edit History</mat-card-subtitle>
            </mat-card-header>
            <mat-vertical-stepper [linear]="false" #stepper>
                <mat-step *ngFor="let editHistory of editHistorys">
                    <ng-template matStepLabel>{{editHistory.EventDateTime}}</ng-template>
                    <mat-chip>{{editHistory.EventType}}</mat-chip>
                    <mat-chip>{{editHistory.EventUser}}</mat-chip>
                </mat-step>
            </mat-vertical-stepper>
            <mat-card-content>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
        </mat-card>
    </mat-drawer>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="mat-card-header-text">
                <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                    function
                </div>
                <span *ngIf="itemData.IsDisabled" class="material-symbols-outlined timer_inactive">
                    timer_off
                </span>
                <span *ngIf="!itemData.IsDisabled" class="material-symbols-outlined timer_active">
                    timer
                </span>
            </div>
            <mat-card-title>{{itemData.Name}}</mat-card-title>
            <mat-card-subtitle>Function: Id-{{itemId}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form *ngIf="itemData">
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Name</mat-label>
                    <input matInput #name maxlength="256" [(ngModel)]="itemData.Name" width="220" name="Email" />
                </mat-form-field>
                <br />
                <mat-form-field class="" style="width: 660px;padding-left: 12px;">
                    <mat-label>Description</mat-label>
                    <input matInput #description maxlength="256" [(ngModel)]="itemData.Description" width="220" name="Description" />
                </mat-form-field>
                <br />
                <mat-form-field class="" style="width:320px; padding-left: 12px;">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="itemData.ManagedFunctionTypeId" [disabled]="itemData.ManagedFunctionId > 0" name="ManagedFunctionTypeId">
                        <mat-option *ngFor="let type of functionRoleTypes" [value]="type.ManagedFunctionTypeId">{{type.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <br />
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>CronTriggerExpression</mat-label>
                    <input matInput #cronTriggerExpression maxlength="256" [(ngModel)]="itemData.CronTriggerExpression" width="220" name="CronTriggerExpression" />
                </mat-form-field>
            </form>
            <mat-slide-toggle [(ngModel)]="itemData.IsDisabled" [checked]="itemData.IsDisabled" color="primary">
                <span style="color:black;font-weight:300;" matTooltip="Stop function from processing">IsDisabled</span>
            </mat-slide-toggle>
            <br />
            <br />
            <mat-card>
                <mat-card-header>
                    <div mat-card-avatar class="mat-card-header-text">
                        <div class="material-symbols-outlined" style="font-size: 16pt;float-left;">
                            ecg_heart
                        </div>
                    </div>
                    <mat-card-title>Managed Fields</mat-card-title>
                    <mat-card-subtitle>View job progress</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <form *ngIf="itemData">
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>LastStartDateTime</mat-label>
                            <input matInput #LastStartDateTime maxlength="256" [ngModel]="itemData.LastStartDateTime | dateAgo:randomNumber" matTooltip="{{itemData.LastStartDateTime | dateUtcToLocal:randomNumber}}" width="220" name="LastStartDateTime" readonly style="color:gray;" [@highlight]="historyChanged" (@highlight.done)="historyChanged = ''" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>LastFinishDateTime</mat-label>
                            <input matInput #LastFinishDateTime maxlength="256" [ngModel]="itemData.LastFinishDateTime | dateAgo:randomNumber" matTooltip="{{itemData.LastFinishDateTime | dateUtcToLocal:randomNumber}}" width="220" name="LastFinishDateTime" readonly style="color:gray;" [@highlight]="historyChanged" (@highlight.done)="historyChanged = ''" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>Duration</mat-label>
                            <input matInput #Duration maxlength="256" [(ngModel)]="itemData.Duration" width="220" name="Duration" readonly style="color:gray;" [@highlight]="historyChanged" (@highlight.done)="historyChanged = ''" />
                        </mat-form-field>
                        <br />
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>NextRunDateTime</mat-label>
                            <input matInput #Duration maxlength="256" [ngModel]="itemData.NextRunDateTime | dateAgo:randomNumber" matTooltip="{{itemData.NextRunDateTime | dateUtcToLocal:randomNumber}}" width="220" name="NextRunDateTime" readonly style="color:gray;" [@highlight]="historyChanged" (@highlight.done)="historyChanged = ''" />
                        </mat-form-field>
                    </form>
                    <mat-slide-toggle [(ngModel)]="itemData.IsRunning" [checked]="itemData.IsRunning" color="primary" disabled="true" [@highlight]="historyChanged" (@highlight.done)="historyChanged = ''">
                        <span style="font-weight:300;" matTooltip="Stop function from processing">IsRunning</span>
                    </mat-slide-toggle>
                    <form *ngIf="itemData">
                        <mat-slide-toggle [(ngModel)]="itemData.IsDue" [checked]="itemData.IsDue" color="primary" disabled="true" [@highlight]="historyChanged" (@highlight.done)="historyChanged = ''">
                            <span style="font-weight:300;">IsDue</span>
                        </mat-slide-toggle>
                    </form>
                </mat-card-content>
                <mat-card-actions>
                    <mat-slide-toggle [(ngModel)]="isAutoRefresh" matTooltip="Scan for changes regularly and update fields if applicable with refreshed job information" [checked]="isAutoRefresh" color="primary">
                        <span style="font-weight:300;">Auto-Refresh</span>
                    </mat-slide-toggle>
                    <button mat-button *ngIf="!isAutoRefresh" (click)="refreshManagedFields()">
                        <span class="material-symbols-outlined">
                            refresh
                        </span>
                        MANUAL REFRESH
                    </button>
                </mat-card-actions>
            </mat-card>
            <br />
            <br />
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab *ngIf="itemData.ManagedFunctionTypeId == 1">
                    <ng-template mat-tab-label>
                        <span>
                            Copy to SQL
                        </span>
                    </ng-template>
                    <br />
                    <form>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>SourceSchemaName</mat-label>
                            <input matInput #SourceSchemaName maxlength="256" [(ngModel)]="itemDataCopySqlToSqlFunctionData.SourceSchemaName" width="220" name="SourceSchemaName" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>SourceTableName</mat-label>
                            <input matInput #SourceTableName maxlength="256" [(ngModel)]="itemDataCopySqlToSqlFunctionData.SourceTableName" width="220" name="SourceTableName" />
                        </mat-form-field>
                        <br />
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>TargetSchemaName</mat-label>
                            <input matInput #TargetSchemaName maxlength="256" [(ngModel)]="itemDataCopySqlToSqlFunctionData.TargetSchemaName" width="220" name="TargetSchemaName" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>TargetTableName</mat-label>
                            <input matInput #TargetTableName maxlength="256" [(ngModel)]="itemDataCopySqlToSqlFunctionData.TargetTableName" width="220" name="TargetTableName" />
                        </mat-form-field>
                        <br />
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>RetentionDays</mat-label>
                            <input matInput #RetentionDays maxlength="256" [(ngModel)]="itemDataCopySqlToSqlFunctionData.RetentionDays" width="220" name="RetentionDays" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>PerRunBatchSize</mat-label>
                            <input matInput #PerRunBatchSize maxlength="256" [(ngModel)]="itemDataCopySqlToSqlFunctionData.PerRunBatchSize" width="220" name="PerRunBatchSize" />
                        </mat-form-field>
                    </form>
                </mat-tab>
                <mat-tab *ngIf="itemData.ManagedFunctionTypeId == 2">
                    <ng-template mat-tab-label>
                        <span>
                            Copy to Datalake
                        </span>
                    </ng-template>
                    <br />
                    <form>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>SourceSchemaName</mat-label>
                            <input matInput #SourceSchemaName maxlength="256" [(ngModel)]="itemDataCopySqlToDatalakeFunctionData.SourceSchemaName" width="220" name="SourceSchemaName" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>SourceTableName</mat-label>
                            <input matInput #SourceTableName maxlength="256" [(ngModel)]="itemDataCopySqlToDatalakeFunctionData.SourceTableName" width="220" name="SourceTableName" />
                        </mat-form-field>
                        <br />
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>TargetTableName</mat-label>
                            <input matInput #TargetTableName maxlength="256" [(ngModel)]="itemDataCopySqlToDatalakeFunctionData.TargetTableName" width="220" name="TargetTableName" />
                        </mat-form-field>
                        <br />
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>RetentionDays</mat-label>
                            <input matInput #RetentionDays maxlength="256" [(ngModel)]="itemDataCopySqlToDatalakeFunctionData.RetentionDays" width="220" name="RetentionDays" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>PerRunBatchSize</mat-label>
                            <input matInput #PerRunBatchSize maxlength="256" [(ngModel)]="itemDataCopySqlToDatalakeFunctionData.PerRunBatchSize" width="220" name="PerRunBatchSize" />
                        </mat-form-field>
                    </form>
                </mat-tab>
                <mat-tab *ngIf="itemData.ManagedFunctionTypeId == 3">
                    <ng-template mat-tab-label>
                        <span>
                            Data Purge
                        </span>
                    </ng-template>
                    <br />
                    <form>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>SourceSchemaName</mat-label>
                            <input matInput #SourceSchemaName maxlength="256" [(ngModel)]="itemDataPurgeSqlFunctionData.SourceSchemaName" width="220" name="SourceSchemaName" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>SourceTableName</mat-label>
                            <input matInput #SourceTableName maxlength="256" [(ngModel)]="itemDataPurgeSqlFunctionData.SourceTableName" width="220" name="SourceTableName" />
                        </mat-form-field>
                        <br />
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>BufferHoldHours</mat-label>
                            <input matInput #BufferHoldHours maxlength="256" [(ngModel)]="itemDataPurgeSqlFunctionData.BufferHoldHours" width="220" name="BufferHoldHours" />
                        </mat-form-field>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>PerRunBatchSize</mat-label>
                            <input matInput #PerRunBatchSize maxlength="256" [(ngModel)]="itemDataPurgeSqlFunctionData.PerRunBatchSize" width="220" name="PerRunBatchSize" />
                        </mat-form-field>
                    </form>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>
                            Recent History
                        </span>
                    </ng-template>
                    <br />
                    <table mat-table matSort #eventSort="matSort" [dataSource]="itemRecentHistory" class="mat-elevation-z8 {{eventsResultsClass}}">
                        <ng-container matColumnDef="Avatar">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item">
                                <span class="material-symbols-outlined" style="font-size: 18pt;">
                                    bolt
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="StartedDateTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> StartedDateTime </th>
                            <td mat-cell *matCellDef="let item" matTooltip="{{item.StartedDateTime | dateUtcToLocal:randomNumber}}"> {{item.StartedDateTime | dateAgo:randomNumber}} </td>
                        </ng-container>
                        <ng-container matColumnDef="FinishedDateTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> FinishedDateTime </th>
                            <td mat-cell *matCellDef="let item" matTooltip="{{item.FinishedDateTime | dateUtcToLocal:randomNumber}}"> {{item.FinishedDateTime | dateAgo:randomNumber}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Duration">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
                            <td mat-cell *matCellDef="let item"> {{item.Duration}} </td>
                        </ng-container>
                        <ng-container matColumnDef="RowsCount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> RowsCount </th>
                            <td mat-cell *matCellDef="let item"> {{item.RowsCount}} </td>
                        </ng-container>
                        <ng-container matColumnDef="MinRowId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> MinRowId </th>
                            <td mat-cell *matCellDef="let item"> {{item.MinRowId}} </td>
                        </ng-container>
                        <ng-container matColumnDef="MaxRowId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> MaxRowId </th>
                            <td mat-cell *matCellDef="let item"> {{item.MaxRowId}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedEventsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedEventsColumns"></tr>
                    </table>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button (click)="save()">
                <span class="material-symbols-outlined">
                    save
                </span>
                SAVE
            </button>
            <button *ngIf="itemId != '0'" mat-button (click)="delete()">
                <span class="material-symbols-outlined">
                    delete_forever
                </span>
                DELETE
            </button>
            <button *ngIf="itemId != '0'" mat-button (click)="historyDrawer.toggle()">
                <span class="material-symbols-outlined">
                    history
                </span>
                CHANGE LOG
            </button>
        </mat-card-actions>
        <mat-card-footer>
            <!--<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
        </mat-card-footer>
    </mat-card>
</mat-drawer-container>
