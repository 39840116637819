import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { ModalDialogSeverity } from '../enums/modal-dialog-severity';
import { ReportsService } from '../services/reports/reports.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    public _baseUrl: string = '???';

    public constructor(public appService: AppService, public reportsService: ReportsService, public router: Router) {

        this, this._baseUrl = this.reportsService.getBaseUrl();
    }

    public ngOnInit(): void { 
    
        const session = this.appService.CurrentSession

        if(!session){
            this.router.navigate(['/login']);
        }
    }

    public showPointers = (): void => {

        this.appService.getPointers().subscribe((r: any) => {

            if (r != null && r != "") {

                this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Pointers", JSON.stringify(r), "OK", "");
            }
        });
    }
}
