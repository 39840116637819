<div class="FullHeightContentContainer">
    <div class="FullHeightContenttHeader">
        <table style="width:100%;">
            <tr>
                <td rowspan="2" style="padding-left:12px;width:1px;">
                    <span class="material-symbols-outlined" style="font-size: 32pt;">
                        finance_chip
                    </span>
                </td>
                <td style="padding-left:38px;">
                    <mat-card-title>Search Invoices</mat-card-title>
                </td>
            </tr>
            <tr>
                <td style="padding-left:42px;">
                    <mat-card-subtitle>Search and edit invoices
                    </mat-card-subtitle>
                </td>
            </tr>
        </table>
    </div>
    <div class="FullHeightContentContent">
        <form *ngIf="searchParameters" (keyup.enter)="search()">
            <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                <mat-label>Creditor Id</mat-label>
                <mat-select [formControl]="creditorsFormControl" [(ngModel)]="searchParameters.CreditorId">
                    <mat-option *ngFor="let creditor of creditorsList" [value]="creditor.CreditorId">{{creditor.CompanyName}} ({{creditor.CreditorId}})</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                <mat-label>IntegrationLinkId</mat-label>
                <input matInput #integrationLinkId maxlength="256" [(ngModel)]="searchParameters.IntegrationLinkId" width="220" name="IntegrationLinkId">
                <mat-icon matSuffix class="material-symbols-outlined" (click)="this.appService.showWildcardHint(3, searchParameters.IntegrationLinkId)" style="font-size: 14pt;">help</mat-icon>
            </mat-form-field>
            <br />
            <mat-form-field class="" style="width:440px;padding-left: 12px;">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="statusesFormControl" [(ngModel)]="searchParameters.AccountStatuses" multiple>
                    <mat-option *ngFor="let status of statusesList" [value]="status.Id">{{status.Name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <div style="margin-left:8px;width:99%;height:calc(100vh - 360px);overflow:auto;">
            <table mat-table matSort [dataSource]="resultsMatTableDataSource" class="mat-elevation-z8 {{resultsClass}}">
                <ng-container matColumnDef="Avatar">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item" (click)="edit(item)">
                        <span class="material-symbols-outlined" style="font-size: 18pt;">
                            finance_chip
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="CreditorId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CreditorId </th>
                    <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.CreditorId}} </td>
                </ng-container>
                <ng-container matColumnDef="CreditorName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Creditor Name</th>
                    <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.CreditorName}} </td>
                </ng-container>
                <ng-container matColumnDef="IntegrationLinkId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> IntegrationLinkId </th>
                    <td mat-cell *matCellDef="let item" (click)="edit(item)">
                        <div>
                            {{item.IntegrationLinkId}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="StatusName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> StatusName </th>
                    <td mat-cell *matCellDef="let item" (click)="edit(item)"> {{item.StatusName}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
    <div class="FullHeightContentFooter">
        <span class="FooterPaginator">
            <mat-paginator [pageSizeOptions]="[20, 50, 100]" aria-label="Select page of items"></mat-paginator>
        </span>
        <span class="ActionButtons">
            <button mat-button (click)="search()">
                <span class="material-symbols-outlined">
                    search
                </span>
                SEARCH
            </button>
        </span>
    </div>
</div>


