<mat-drawer-container class="example-container" autosize>
    <mat-drawer #historyDrawer class="example-sidenav" mode="side" position="end">
        <mat-card>
            <mat-card-header>
                <div mat-card-avatar class="mat-card-header-text">
                    <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                        history
                    </div>
                </div>
                <mat-card-title>History</mat-card-title>
                <mat-card-subtitle>Edit History</mat-card-subtitle>
            </mat-card-header>
            <mat-vertical-stepper [linear]="false" #stepper>
                <mat-step *ngFor="let editHistory of editHistorys">
                    <ng-template matStepLabel>{{editHistory.EventDateTime}}</ng-template>
                    <mat-chip>{{editHistory.EventType}}</mat-chip>
                    <mat-chip>{{editHistory.EventUser}}</mat-chip>
                </mat-step>
            </mat-vertical-stepper>
            <mat-card-content>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
        </mat-card>
    </mat-drawer>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="mat-card-header-text">
                <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                    folder_data
                </div>
            </div>
            <mat-card-title>{{itemData.Name}}</mat-card-title>
            <mat-card-subtitle>NavigationNode: Id-{{itemData.NavigationNodeId}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form *ngIf="itemData">
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Name</mat-label>
                    <input matInput #emailAddress maxlength="256" [(ngModel)]="itemData.Name" width="220" name="Name" />
                </mat-form-field>
                <br />
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Parent Id</mat-label>
                    <input matInput #emailAddress maxlength="256" [(ngModel)]="itemData.ParentNavigationNodeId" width="220" name="ParentNavigationNodeId" />
                </mat-form-field>
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Description</mat-label>
                    <input matInput #emailAddress maxlength="256" [(ngModel)]="itemData.Description" width="220" name="Description" />
                </mat-form-field>
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Display</mat-label>
                    <input matInput #emailAddress maxlength="256" [(ngModel)]="itemData.DisplayText" width="220" name="DisplayText" />
                </mat-form-field>
                <br />
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Route Path</mat-label>
                    <input matInput #firstName maxlength="256" [(ngModel)]="itemData.RoutePath" width="220" name="RoutePath" />
                </mat-form-field>
                <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                    <mat-label>Icon Name</mat-label>
                    <input matInput #lastName maxlength="256" [(ngModel)]="itemData.MatIconName" width="220" name="IconName" />
                </mat-form-field>
            </form>
            <mat-tab-group *ngIf="itemId != '0'" mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span matBadge="{{this.itemData.Roles.length == 0 ? '' : this.itemData.Roles.length}}" matBadgeSize="small" matBadgeOverlap="false">
                            Roles
                        </span>
                    </ng-template>
                    <br />
                    <form>
                        <mat-form-field class="" style="width: 220px; padding-left: 12px;">
                            <mat-label>Available Roles</mat-label>
                            <mat-select [formControl]="rolesTabFormControl" [(ngModel)]="selectedRole">
                                <mat-option *ngFor="let role of availableRoles" [value]="role">{{role.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-button (click)="addRole()" [disabled]="this.selectedRole == null">
                            <span class="material-symbols-outlined">
                                add
                            </span>
                            ADD ROLE
                        </button>
                    </form>
                    <br />
                    <table mat-table matSort #roleSort="matSort" [dataSource]="resultsRolesMatTableDataSource" class="mat-elevation-z8 {{rolesResultsClass}}">
                        <ng-container matColumnDef="Avatar">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item" (click)="editRole(item)">
                                <span class="material-symbols-outlined" style="font-size: 18pt;">
                                    work
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="RoleName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> RoleName </th>
                            <td mat-cell *matCellDef="let item" (click)="editRole(item)"> {{item.RoleName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="RemoveRole">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let item" (click)="removeRole(item)">
                                <span class="material-symbols-outlined" style="font-size: 18pt;">
                                    delete
                                </span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedRolesColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedRolesColumns;"></tr>
                    </table>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button (click)="save()">
                <span class="material-symbols-outlined">
                    save
                </span>
                SAVE
            </button>
            <button *ngIf="itemId != '0'" mat-button (click)="appService.showPendingFeatureMessage()">
                <span class="material-symbols-outlined">
                    delete
                </span>
                DELETE
            </button>
            <button *ngIf="itemId != '0'" mat-button (click)="historyDrawer.toggle()">
                <span class="material-symbols-outlined">
                    history
                </span>
                CHANGE LOG
            </button>
        </mat-card-actions>
        <mat-card-footer>
            <!--<mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
        </mat-card-footer>
    </mat-card>
</mat-drawer-container>
