import { Component, OnInit, ViewChild } from '@angular/core'; import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { ReferenceDataService } from '../../services/referencedata/reference-data.service';
import { UserService } from '../../services/user/user.service';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserEditComponent } from '../user-edit/user-edit.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.css'],
})

export class UserSearchComponent implements OnInit {

    public creditorsList: any = [];
    public searchParameters: any = null;
    public results: any = null;
    public resultsRecordCount: number = 0;
    public resultsMatTableDataSource: any = null;
    public displayedColumns: string[] = ['Avatar', 'Username', 'FirstName', 'LastName', 'OpenModal'];
    public advancedSearchClass: string = 'display-none';
    public resultsClass: string = 'display-none';

    @ViewChild(MatSort) sort: MatSort = new MatSort();
    @ViewChild(MatPaginator) paginator: any;

    public constructor(public appService: AppService,
                       public userService: UserService,
                       public referenceDataService: ReferenceDataService,
                       public matDialog: MatDialog) {
    }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        forkJoin(this.userService.getDefaultSearchParameters(),
                 this.referenceDataService.getCreditors()).subscribe(([searchParameters,
                                                                       creditors]) => {
            this.searchParameters = searchParameters;
            this.creditorsList = creditors;

            this.appService.IsAppBusy = false;
        });
    }

    public toggleAdvancedSearch = (): void => {

        if (this.advancedSearchClass == 'display-none') {
            this.advancedSearchClass = 'display-block';
        } else {
            this.advancedSearchClass = 'display-none';
        }
    }

    public search = () => {


        let searchParameters: any = JSON.parse(JSON.stringify(this.searchParameters));          // clone the search parameters as there may be need to manipulate it
            
        if (searchParameters.CreditorIds == null || searchParameters.CreditorIds.length == 0) { // search all creditor ids if none selected

            searchParameters.creditorIds = this.creditorsList.map((i: { CreditorId: string; }) => i.CreditorId);
        }

        this.appService.IsAppBusy = true;
        this.userService.search(searchParameters).subscribe((records: any) => {

            if (records != null) {

                this.appService.IsAppBusy = false;

                if (records.length == 0) {

                    this.results = [];
                    this.resultsClass = "display-none";
                    this.resultsRecordCount = 0;
                    this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Empty Search Results", "No data was returned for the criteria.  Check the creditor id, email address and names etc", "OK", "");

                } else {

                    this.resultsClass = "display-block";
                    this.results = records;
                    this.resultsRecordCount = records.length;
                    this.resultsMatTableDataSource = new MatTableDataSource(records);
                    this.resultsMatTableDataSource.sort = this.sort;
                    this.resultsMatTableDataSource.paginator = this.paginator;
                }
            }
        });
    }

    public edit = (item: any, isModal: boolean = false) => {

        try {

            if (isModal) {

                const dialogConfig = new MatDialogConfig();

                //dialogConfig.disableClose = false;
                dialogConfig.autoFocus = true;
                dialogConfig.width = "90%";
                dialogConfig.height = "90%";
                dialogConfig.data = item.Id;

                this.matDialog.open(UserEditComponent, dialogConfig);

            } else {

                this.appService.navigateTo('user/' + item.Id);
            }

        } catch (e) {

            this.appService.ShowModalDialog(ModalDialogSeverity.Error, "Error Navigating to Item", "Unable to navigate to user " + item.Id, "OK :(", "");
        }
    }

    public create = (): void => {

        let route = 'user/0';

        this.appService.navigateTo(route);
    }

    public delete = (user: any): void => {

        let proceedResponse = "PROCEED TO DELETE";

        this.appService.ShowModalDialog(ModalDialogSeverity.Warning,
                                        "Confirm Deletion",
                                        "Are you sure you want to delete user " + user.Name + ".  If you are 100% sure, click PROCEEED TO DELETE, otherwise CANCEL",
                                        proceedResponse,
                                        "CANCEL").subscribe((response) => {

            if (response == proceedResponse) {

                this.appService.IsAppBusy = true;

                this.userService.delete(user.Id).subscribe((r: any) => {

                    if (r != '') {

                        this.appService.IsAppBusy = false;

                        if (r instanceof HttpErrorResponse) {

                            this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                        } else {

                            // refresh the results grid
                            const itemIndex = this.results.map((i: { Id: any; }) => i.Id).indexOf(user.Id);

                            if (itemIndex > -1) {

                                this.results.splice(itemIndex, 1);
                            }

                            this.resultsRecordCount = this.results.length;
                            this.resultsMatTableDataSource = new MatTableDataSource(this.results);
                            this.resultsMatTableDataSource.sort = this.sort;
                            this.resultsMatTableDataSource.paginator = this.paginator;
                        }
                    }
                });
            }
        });
    }
}
