import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { ContactService } from '../../services/contact/contact.service';
import { MatInputModule } from '@angular/material/input';
import { FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';

@Component({
    selector: 'app-contact-basic-test',
    templateUrl: './contact-basic-test.component.html',
    styleUrls: ['./contact-basic-test.component.css']
})

export class ContactBasicTestComponent implements OnInit {

    public searchModel: any = {
        AccountContactId: '',
        ResultObject: null,
        ResultJson: ''
    }

    public constructor(public appService: AppService,
                       public contactService: ContactService,
                       private route: ActivatedRoute) {

        const parameters = route.params;
        let accountContactId = '';

        if (parameters != null) {

            parameters.subscribe(p => {

                accountContactId = 'creditor/' + p['creditorId'] + '/accountcontact/' + p['integrationLinkId'];
                this.searchModel.AccountContactId = accountContactId;

                this.getSqlAccountContact();
            });
        }

        this.searchModel.AccountContactId = accountContactId;
    }

    public ngOnInit(): void { }

    public getSqlAccountContact = () => {

        if (this.searchModel.AccountContactId == '') {

            this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Missing Input", "You didn't enter an account contact id.  Enter an account contact id in thre format creditor/{creditorid}/accountcontact/{integrationlinkid}.  So for example creditor/1/accountcontact/abc123.", "GOTCHA", "");

        } else {

            this.appService.IsAppBusy = true;
            this.contactService.getSampleSqlQuery("asdfa");

            this.contactService.getSqlAccountContact(this.searchModel.AccountContactId).subscribe((r: any) => {

                if (r != '') {

                    this.searchModel.ResultObject = r;
                    this.searchModel.ResultJson = JSON.stringify(r, null, 3);
                    this.appService.IsAppBusy = false;
                }
            });
        }
    }

    public saveSqlAccountContact = () => {

        this.appService.IsAppBusy = true;

        this.contactService.saveSqlAccountContact(this.searchModel.ResultJson).subscribe((r: any) => {

            if (r != '') {

                if (r instanceof HttpErrorResponse) {

                    this.appService.IsAppBusy = false;
                    this.appService.ShowModalDialog(ModalDialogSeverity.Error, "Error Saving Contact", "There was an error saving the contact.  Error has been logged.  AI has already determined the cause based on log details and ticket number for fix is 1234 with an anticipated fix window of 8 hours as of (current time here)", "HAVE A NICE DAY", "");

                } else {

                    this.searchModel.ResultObject = r;
                    this.searchModel.ResultJson = JSON.stringify(r, null, 3);
                    this.appService.IsAppBusy = false;
                }
            }
        });
    }

    public getSampleSqlQuery = () => {

        const sampleSqlQuery = this.contactService.getSampleSqlQuery(this.searchModel.AccountContactId);

        this.appService.ShowModalDialog(ModalDialogSeverity.Info, "Sample SQL Script for current Contact", sampleSqlQuery, "AWESOME !", "");
    }
}
