import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../../app.service';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { NavigationNode } from '../../interfaces/navigation-node';

@Component({
  selector: 'app-route-listing',
  templateUrl: './route-listing.component.html',
  styleUrls: ['./route-listing.component.css']
})

export class RouteListingComponent implements OnInit {

    public activatedRoute: ActivatedRoute;
    public navigationNodeId: number = 0;
    public navigationNode: any = null;

    public constructor(public appService: AppService, public route: ActivatedRoute) {

        this.activatedRoute = route;
        const parameters = this.route.params;

        if (parameters != null) {

            parameters.subscribe(p => {

                this.navigationNodeId = p['navigationNodeId'];

                let n: NavigationNode = this.appService.getNavigationNodeById(this.navigationNodeId);

                if (n != null) {

                    this.navigationNode = n;
                }
            });
        }
    }

    public ngOnInit(): void { }
}
