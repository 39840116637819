import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../../app.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { FlatNode } from '../../interfaces/flat-node';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { NavigationNode } from '../../interfaces/navigation-node';
import { AuthService } from '../../services/auth/auth.service';
import { NavigationNodeService } from '../../services/navigation-node/navigation-node.service';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-navigator',
    templateUrl: './navigator.component.html',
    styleUrls: ['./navigator.component.css']
})

export class NavigatorComponent implements OnInit {

    private _transformer = (node: NavigationNode, level: number) => {

        let result = {
            id: node.NavigationNodeId,
            expandable: !!node.NavigationNodes && node.NavigationNodes.length > 0,
            name: node.DisplayText,
            route: node.RoutePath,
            level: level,
            color: "black",
            maticon: node.MatIconName
        };

        return result;
    };

    public treeControl = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable,
    );

    public treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.NavigationNodes
    );

    public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    public hasChild = (_: number, node: FlatNode) => node.expandable;
    public isMasterMember: boolean = false;

    public constructor(public appService: AppService,
                       private authService: AuthService,
                       private userService: UserService, 
                       private navigationNodeService: NavigationNodeService) {
    }

    public ngOnInit(): void {

        this.authService.loggedIn.subscribe((currentUser: any) => {

            this.isMasterMember = this.appService.CurrentSession.AuthenticatedUser.User.Roles.filter((i: string) => i.toUpperCase() == 'MASTER').length == 1;
            this.loadNodes(true);
        });

        this.authService.loggedOut.subscribe((r: any) => {

            const publicNodes = this.appService.getPublicNodes();

            this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
            this.dataSource.data = publicNodes;
        });

        this.navigationNodeService.navigationUpdated.subscribe((r: any) => {

            this.loadNodes(false);
        });

        this.userService.currentUserUpdated.subscribe((r: any) => {

            this.loadNodes(false);
        });

        this.loadNodes(true);
    }

    private loadNodes = (isBroadcast: boolean): void => {

        this.appService.getNavigationNodes().subscribe((nodes: any) => {

            if (nodes != null) {

                if (nodes.length > 0) {

                    this.dataSource.data = nodes;

                    if (isBroadcast) {

                        this.appService.broadcastLastAppNavigatedNode(); // force the breadcrumbs to refresh if navigator is built after navigating
                    }
                }
            }
        });
    }

    public edit = (navigationNode: any) => {

        this.appService.navigateTo('/navigationnode/' + navigationNode.id);
    }

    public add = (navigationNode: any) => {

        this.appService.navigateTo('/navigationnode/0/' + navigationNode.id);
    }
}
