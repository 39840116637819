<mat-drawer-container class="example-container" autosize>
    <mat-drawer #helpDrawer class="example-sidenav" mode="side" position="end">
        <mat-card>
            <mat-card-header>
                <div mat-card-avatar class="mat-card-header-text">
                    <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                        local_library
                    </div>
                </div>
                <mat-card-title>Profile</mat-card-title>
                <mat-card-subtitle>
                    <span>
                        Please read completely before proceeding
                        <br />
                        <a (click)="helpDrawer.toggle()">CLOSE</a>
                    </span>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style="border-left:solid 4px dimgray;min-width: 280px;max-width:280px;">
                <ul>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Development Data
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            All data shown comes from Dev1 both Auth and Remitter Main SQL Databases.
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Alpha Release
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            This is an alpha release which means the product may contain features that may throw errors OR omit certain validations that protect from, for example, removing the last role from a user.
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Design
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            In an effort to apply good design practices, this site has almost no aesthetics applied.  Instead, the focus is on structure and layout so that the best user experience can be established at which point colors and graphics can be applied.
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Code-Base
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            The codebase for this project is extremely simple and uses 'big-lego' mentality.  That is to say that anyone can build big-legos since little legos are more advanced.  This is a not an advanced project and as such, pretty much any competent, intermediate developer can get a grasp of this project quickly and become a productive contributor.
                            <br />
                            <br />
                            Code-base is located at <a href="https://github.com/Remitter/Remitter.Tools" target="_blank">Remitter.Tools</a>
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Navigation
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            Use the left navigator to get around.  Some items are similar to a typical file explorer (such as Windows file explorer).  It is similar in the sense that the content pane will show a list of sub-menu-items that reside within the clicked menu item.  Some items are simply place-holders to demonstrate menu structure.
                            <br />
                            <br />
                            The main functional items are <br /><br />Administration -> Users<br />Adminstration -> Roles<br />Reports -> Notifications<br /><br />as well as the experimental Contacts and Invoices inside the Testing folder.
                            <br />
                            <br />
                            As a fun challenge.  Try to find the hamburger and soda ;)
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Roles
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            Your profile has been created with ADMIN role membership, if you remove this role you effectively demote yourself and will not be able to restore it and will need to ask another ADMIN role member to restore it.<br /><br />Here are the current Roles:<br /><br />MASTER - full power including Navigation conrol<br />ADMIN - administer users and roles<br />REPORTS - view and run reports<br />TESTER - access to the testing section<br />EVERYONE - access to your profile<br /><br />Roles determine what you can see and do inside the app.  AVOID, removing your last role.  MASTER role is the most powerful role.  Note that roles will dictate which menu items you can see.NOTE, it's recommended (at present) to NOT alter the name of a role.  Once a role is created, please keep the name as is.  If you need to re-name, it's better to create a new role, add users and then remove the unwanted role.
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Claims
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            Claims, namely those of type 'creditor' determine data access.  For example, visit the Reports -> Notifications item and note that the Creditor drop-down lists only those creditors that match up with your claims.  Go to your User account (via Admin -> Users) and add remove claims, then re-login to see the list of available creditors is synced with your claims
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Navigation Edit
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            If you are a member of the MASTER role, the navigation menu will allow you to add, edit and delete menu items.  This is the most 'alpha' part of the app and requires you understand the field values and how they relate to the rendering and behavior of the menu.  Take care in any menu edits !
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Proof-of-Concept
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            Some things might seem a little 'clunky'.  For example, 'ADD USER' as seen in the Roles editor requires you to find a user in a list and then click the 'ADD USER' button to put the user into a list that then needs to be saved.  Adding claims is a bit cumbersome.  These features, although slightly cumbersome are first passes at building out roles and of course can be streamlined.  The purpose of this app is to first and foremost, demonstrate a working Role management system.
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Change Tracking
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            Some editors have a 'HISTORY' button that shows a simple list of edits.  This list is static and exists as a proof-of-concept.  Change tracking for Users and Roles is not activated.  However, menu change tracking is active and the database captures activity by user.  Although this data is not loaded into the history panel, it is being captured.
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                Sample Users, Roles etc
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            Some pre-loaded sample users have been created all of which use the 'ghostbusters.non' faux email address.<br /><br />These users exists so that you can learn how to use the system while in alpha-mode to avoid doing something bad.  And by bad we mean, <a href="https://en.wikipedia.org/wiki/Proton_pack#Crossing_the_streams" target="_blank">Total-Protonic-Reversal</a>, as in every atom in your body exploding at the speed of light should you cross the steams and remove the last role!<br /><br /> The users are:<br /><br />peter&#64;ghostbusters.non<br />ray&#64;ghostbusters.non<br />janine&#64;ghostbusters.non<br /><br />NOTE: Send a DM through Teams for credentials please.
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" onclick="this.parentElement.parentElement.children[1].className = 'item-collapsed'; parentElement.parentElement.nextSibling.children[1].className = 'item-expanded';" color="primary">OK</mat-checkbox>
                            <br />
                        </div>
                    </li>
                    <li>
                        <h4>
                            <a onclick="if (this.parentElement.parentElement.children[1].className == 'item-expanded') { this.parentElement.parentElement.children[1].className = 'item-collapsed'; } else { this.parentElement.parentElement.children[1].className = 'item-expanded'; }">
                                What module would you build next ?
                            </a>
                        </h4>
                        <div class="item-collapsed" style="margin-bottom:24px;">
                            How about Billing ?
                            <br />
                            <br />
                            Transfering the command-line billing system to a page located here that is adminstered via a new role could be done in a day or two.
                            <br />
                            <br />
                            <mat-checkbox class="example-margin" (click)="helpDrawer.toggle()">Lets Go</mat-checkbox>
                            <br />
                        </div>
                    </li>
                </ul>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
        </mat-card>
    </mat-drawer>
    <mat-drawer #historyDrawer class="example-sidenav" mode="side" position="end">
        <mat-card>
            <mat-card-header>
                <div mat-card-avatar class="mat-card-header-text">
                    <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                        history
                    </div>
                </div>
                <mat-card-title>History</mat-card-title>
                <mat-card-subtitle>Edit History</mat-card-subtitle>
            </mat-card-header>
            <mat-vertical-stepper [linear]="false" #stepper>
                <mat-step *ngFor="let editHistory of editHistorys">
                    <ng-template matStepLabel>{{editHistory.EventDateTime}}</ng-template>
                    <mat-chip>{{editHistory.EventType}}</mat-chip>
                    <mat-chip>{{editHistory.EventUser}}</mat-chip>
                </mat-step>
            </mat-vertical-stepper>
            <mat-card-content>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
        </mat-card>
    </mat-drawer>
    <mat-card>
        <mat-card-header>
            <div mat-card-avatar class="mat-card-header-text">
                <div class="material-symbols-outlined" style="font-size: 24pt;float-left;">
                    face
                </div>
            </div>
            <mat-card-title>{{appService.CurrentSession.AuthenticatedUser.User.FirstName}} {{appService.CurrentSession.AuthenticatedUser.User.LastName}}</mat-card-title>
            <mat-card-subtitle>{{appService.CurrentSession.AuthenticatedUser.User.Email}}</mat-card-subtitle>
            <button mat-fab (click)="helpDrawer.toggle()" color="primary" class="top-readme-button" style="position:relative;top:12px;">
                <span class="material-symbols-outlined">
                    campaign
                </span>
            </button>
        </mat-card-header>
        <mat-card-content>
            <h2>My User</h2>
            <br />
            <mat-chip-listbox>
                <mat-chip>
                    <div class="material-symbols-outlined" style="font-size: 14pt;padding-right:8px">
                        person
                    </div>
                    <a (click)="editUser()">{{appService.CurrentSession.AuthenticatedUser.User.FirstName}} {{appService.CurrentSession.AuthenticatedUser.User.LastName}}</a>
                </mat-chip>
            </mat-chip-listbox>
            <br />
            <br />
            <h2>My Roles</h2>
            <br />
            <mat-chip-listbox>
                <mat-chip *ngFor="let roleName of userRoleNames" class="example-box">
                    <div class="material-symbols-outlined" style="font-size: 12pt;padding-right:8px">
                        work
                    </div>
                    <a (click)="editRole(roleName)">{{roleName}}</a>
                </mat-chip>
            </mat-chip-listbox>
            <br />
            <div style="color: grey">Role membership determine which resources you are able to access</div>
            <br />
            <br />
            <h2>My Creditor Claims</h2>
            <br />
            <mat-chip-listbox>
                <mat-chip *ngFor="let creditor of creditors" class="example-box" matTooltip="{{creditor.CreditorId}}">
                    <div class="material-symbols-outlined" style="font-size: 12pt;padding-right:8px">
                        interests
                    </div>
                    {{creditor.CompanyName}}
                </mat-chip>
            </mat-chip-listbox>
            <br />
            <div style="color: grey">These are claims of type 'creditorId'.  These claims determine what data you can access</div>
            <br />
        </mat-card-content>
        <mat-card-actions>
            <button mat-fab (click)="helpDrawer.toggle()" class="bottom-readme-button" color="primary">
                <span class="material-symbols-outlined">
                    campaign
                </span>
            </button>
        </mat-card-actions>
        <mat-card-footer>
        </mat-card-footer>
    </mat-card>
</mat-drawer-container>
