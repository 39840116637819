import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { ModalDialogData } from '../../interfaces/modal-dialog-data';

@Component({
    selector: 'app-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.css']
})

export class ModalDialogComponent implements OnInit {

    public _titleiconclassname = '';
    public _titleicontypename = '';
    public _titleiconname = '';
    public _titleiconstyle = '';

    public constructor(public modaldialogreference: MatDialogRef<ModalDialogComponent>,
                       @Inject(MAT_DIALOG_DATA) public modaldialogdata: ModalDialogData) {

        switch (modaldialogdata.Severity) {

            case ModalDialogSeverity.Success:

                this._titleiconclassname = 'icon-success-color';
                this._titleicontypename = 'far';
                this._titleiconname = 'fa-thumbs-up';

                break;

            case ModalDialogSeverity.Info:

                this._titleiconclassname = 'material-icons search-icon icon-info-color';
                this._titleicontypename = 'far';
                this._titleiconname = 'fa-info-square';

                break;

            case ModalDialogSeverity.Warning:

                this._titleiconclassname = 'material-icons search-icon icon-warning-color';
                this._titleicontypename = 'far';
                this._titleiconname = 'fa-exclamation-square';

                break;

            case ModalDialogSeverity.Error:

                this._titleiconclassname = 'material-icons search-icon icon-error-color';
                this._titleicontypename = 'far';
                this._titleiconname = 'fa-thumbs-down';

                break;
        }
    }

    public ngOnInit() {

    }

    public confirm = () => {

        this.modaldialogreference.close(this.modaldialogdata.AcceptButtonText);
    }

    public cancel = () => {

        this.modaldialogreference.close(this.modaldialogdata.CancelButtonText);
    }
}
