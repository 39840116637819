export class DateHelper {

    public convertUTCDateToLocalDate = (date: any) => {

        var timeZoneOffset = date.getTimezoneOffset() * -1;

        date.setTime(date.getTime() + (timeZoneOffset * 60 * 1000));

        return date;
    }

    public convertDateToDateAgoString = (value: any) => {

        let nowLocal = new Date();
        let candidateUtc = new Date(value);
        let dateHelper = new DateHelper();
        let candidateLocal = this.convertUTCDateToLocalDate(candidateUtc);

        let seconds = Math.floor((+nowLocal - +candidateLocal) / 1000);

        const intervals: { [key: string]: number } = {
            'year': 31536000,
            'month': 2592000,
            'week': 604800,
            'day': 86400,
            'hour': 3600,
            'minute': 60,
            'second': 1
        };

        if (seconds >= 0) { // past dates

            if (seconds < 19) {

                return 'Just now';
            }

            let counter;

            for (const i in intervals) {

                counter = Math.floor(seconds / intervals[i]);

                if (counter > 0) {

                    if (counter === 1) {
                        return counter + ' ' + i + ' ago';
                    } else {
                        return counter + ' ' + i + 's ago';
                    }
                }
            }

        } else {

            seconds = seconds * -1;

            if (seconds < 19) {

                return 'any second';
            }

            let counter;

            for (const i in intervals) {

                counter = Math.floor(seconds / intervals[i]);

                if (counter > 0) {

                    if (counter === 1) {
                        return 'in ' + counter + ' ' + i;
                    } else {
                        return 'in ' + counter + ' ' + i + 's';
                    }
                }
            }
        }

        return value;
    }
}
