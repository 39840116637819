<div class="FullHeightContentContainer">
    <div class="FullHeightContenttHeader">
        <table style="width:100%;">
            <tr>
                <td rowspan="2" style="padding-left:12px;width:1px;">
                    <span class="material-symbols-outlined" style="font-size: 32pt;">
                        finance_chip
                    </span>
                </td>
                <td style="padding-left:38px;">
                    <mat-card-title>SQL AccountInvoice Basic Testing</mat-card-title>
                </td>
            </tr>
            <tr>
                <td style="padding-left:42px;">
                    <mat-card-subtitle>Test AccountInvoice Get and Save</mat-card-subtitle>
                </td>
            </tr>
        </table>
    </div>
    <div class="FullHeightContentContent">
        <form>
            <mat-form-field class="" style="width:220px;padding-left: 12px;">
                <mat-label>AccountInvoiceId</mat-label>
                <input matInput #message maxlength="256" [(ngModel)]="searchModel.AccountInvoiceId" width="220" aceholder="Enter path-based ID" name="AccountInvoiceId" />
                <mat-hint align="start">Enter fullpath such as creditor/2/accountinvoice/INV-L7YXOXY3</mat-hint>
            </mat-form-field>
            <br />
            <mat-form-field style="width:96%;">
                <div style="display:none;">
                    <input matInput #accountInvoiceId maxlength="256" [(ngModel)]="searchModel.AccountInvoiceId" name="AccountInvoiceId" />
                </div>
                <textarea [(ngModel)]="searchModel.ResultJson" name="ResultJson" style="font-family:'Courier New';margin-left:8px;width:99%;height:calc(100vh - 360px);overflow:auto;background-color:aliceblue;color:black;">
                </textarea>
            </mat-form-field>
        </form>
    </div>
    <div class="FullHeightContentFooter">
        <span class="ActionButtons">
            <button mat-button (click)="saveSqlAccountInvoice()">
                <span class="material-symbols-outlined">
                    save
                </span>
                SAVE
            </button>
            <button mat-button (click)="getSampleSqlQuery()">
                <span class="material-symbols-outlined">
                    dynamic_form
                </span>
                GENERATE CHECK QUERY
            </button>
        </span>
    </div>
</div>

