import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service';
import { ManagedFunctionService } from '../../services/managed-function/managed-function.service';
import { ManagedFunctionType } from '../../enums/managed-function-type';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDialogSeverity } from '../../enums/modal-dialog-severity';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, forkJoin, interval, Observable, Subscription } from 'rxjs';
import { TimeInterval } from 'rxjs/internal/operators/timeInterval';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { DateHelper } from '../../utilities/date-helper';

@Component({
    selector: 'app-function-edit',
    templateUrl: './function-edit.component.html',
    styleUrls: ['./function-edit.component.css'],
    animations: [
        trigger('highlight', [
            transition('*=>hasHistoryChanged', animate('2000ms', keyframes([
                style({ backgroundColor: 'initial', boxShadow: 'none', offset: 0 }),
                style({ backgroundColor: '#cce6ff', boxShadow: '0 0 5px #cce6ff', offset: 0.1 }),
                style({ backgroundColor: 'initial', boxShadow: 'none', offset: 1 }),
            ])))
        ])
    ]
})

export class FunctionEditComponent implements OnInit {

    public itemId: string = "";
    public functionRoleTypes: any = null;
    public itemData: any = null;
    public itemDataCopySqlToSqlFunctionData: any = null;
    public itemDataPurgeSqlFunctionData: any = null;
    public itemDataCopySqlToDatalakeFunctionData: any = null;
    public itemManagedFieldsOnlyData: any = null;
    public itemDataHistoryData: any = null;
    public randomNumber: number = -1;
    public itemRecentHistory: any = null;
    public isIsRunningDisabled: boolean = true;
    public eventsResultsClass: string = "display-none";
    public displayedEventsColumns: string[] = ['Avatar', 'StartedDateTime', 'FinishedDateTime', 'Duration', 'RowsCount', 'MinRowId', 'MaxRowId'];
    public editHistorys: any = [{ EventDateTime: "12/05/2023", EventType: "Created", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "01/05/2024", EventType: "Modified", EventUser: "Joe.Banks", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "Deleted", EventUser: "Roy.Munson", EventCommentary: "" }, { EventDateTime: "12/06/2023", EventType: "UnDeleted", EventUser: "Joe.Banks", EventCommentary: "This should not have been deleted.  Reactivating" }];
    public isChecked: boolean = true;
    public historyChanged: string = "";
    public isAutoRefresh: boolean = false;

    private refreshIntervalMs: any = interval(10000);
    private lastLastStartDateTimePiped: string = "";
    private lastLastFinishDateTimePiped: string = "";
    private lastDuration: string = "";
    private lastNextRunDateTimePiped: string = "";
    private lastIsRunning: boolean = false;
    private lastIsDue: boolean = false;

    @ViewChild('eventSort') roleSort: any;

    public constructor(public appService: AppService,
                       private functionService: ManagedFunctionService,
                       private route: ActivatedRoute,
                       private location: Location) {

        const parameters = route.params;

        if (parameters != null) {

            parameters.subscribe(p => {

                this.itemId = p["functionId"];
            });
        }
    }

    public ngOnInit(): void {

        this.appService.IsAppBusy = true;

        forkJoin(this.functionService.getManagedFunctionTypes()).subscribe(([functionRoleTypes]) => {

            this.functionRoleTypes = functionRoleTypes;
            this.loadData(false);
        });
    }

    public loadData = (isAutoRefresh: boolean): void => {

        if (!isAutoRefresh) {

            this.appService.IsAppBusy = true;
        }

        this.functionService.getById(parseInt(this.itemId)).subscribe((f: any) => {

            if (f != null) {

                this.itemData = f;

                this.loadFunctionTab().subscribe((tab: any) => {

                    if (tab != null) {

                        this.functionService.getRecentHistory(parseInt(this.itemId)).subscribe((h: any) => {

                            if (h != null) {

                                this.itemDataHistoryData = h;
                                this.itemRecentHistory = new MatTableDataSource(this.itemDataHistoryData);

                                if (h.length > 0) {

                                    this.eventsResultsClass = "display-block";
                                }

                                this.appService.IsAppBusy = false;

                                this.refreshIntervalMs.subscribe((v: any) => {

                                    if (this.isAutoRefresh) {

                                        this.refreshManagedFields();
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    public refreshManagedFields = () => {

        this.loadManagedFields();

        this.randomNumber = Math.random() * 10; // this causes pipes to re-trigger and output values such as 'in 3 minutes' 

        if (this.itemData.LastStartDateTime != this.itemManagedFieldsOnlyData.LastStartDateTime) {

            this.itemData.LastStartDateTime = this.itemManagedFieldsOnlyData.LastStartDateTime;
            this.itemData.LastFinishDateTime = this.itemManagedFieldsOnlyData.LastFinishDateTime;
            this.itemData.Duration = this.itemManagedFieldsOnlyData.Duration;
            this.itemData.IsRunning = this.itemManagedFieldsOnlyData.IsRunning;
            this.itemData.NextRunDateTime = this.itemManagedFieldsOnlyData.NextRunDateTime;
            this.itemData.IsDue = this.itemManagedFieldsOnlyData.NextRunDateTime;

            this.functionService.getRecentHistory(parseInt(this.itemId)).subscribe((latestHistory: any) => {

                if (latestHistory != null) {

                    const latestHistoryRecord = latestHistory[0];
                    this.itemDataHistoryData.unshift(latestHistoryRecord);

                    this.itemRecentHistory = new MatTableDataSource(this.itemDataHistoryData);
                }
            });
        }
    }

    public loadFunctionTab = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);

        if (this.itemData.ManagedFunctionTypeId == ManagedFunctionType.DataCopySqlToSqlFunction) {

            this.functionService.getDataCopySqlToSqlFunctionById(parseInt(this.itemId)).subscribe((f: any) => {

                if (f != null) {
f
                    this.itemDataCopySqlToSqlFunctionData = f;
                    result.next(f);
                    result.complete();
                }
            });

        } else if (this.itemData.ManagedFunctionTypeId == ManagedFunctionType.DataCopySqlToDatalakeFunction) {

            this.functionService.getDataCopySqlToDatalakeFunctionById(parseInt(this.itemId)).subscribe((f: any) => {

                if (f != null) {

                    this.itemDataCopySqlToDatalakeFunctionData = f;
                    result.next(f);
                    result.complete();
                }
            });

        } else if (this.itemData.ManagedFunctionTypeId == ManagedFunctionType.DataPurgeSqlFunction) {

            this.functionService.getDataPurgeFunctionById(parseInt(this.itemId)).subscribe((f: any) => {

                if (f != null) {

                    this.itemDataPurgeSqlFunctionData = f;
                    result.next(f);
                    result.complete();
                }
            });

        }

        return result;
    }

    public loadManagedFields = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);

        this.functionService.getManagedFunctionManagedFields(parseInt(this.itemId)).subscribe((f: any) => {

            if (f != null) {

                let dateHelper = new DateHelper();

                const lastStartDateTimePiped = dateHelper.convertDateToDateAgoString(f.LastStartDateTime);
                const lastFinishedDateTimePiped = dateHelper.convertDateToDateAgoString(f.LastFinishDateTime);
                const duration = f.Duration;
                const nextRunDateTimePiped = dateHelper.convertDateToDateAgoString(f.NextRunDateTime);
                const isRunning = f.IsRunning;
                const isDue = f.IsDue;

                if (this.lastLastStartDateTimePiped != lastStartDateTimePiped
                    || this.lastLastFinishDateTimePiped != lastFinishedDateTimePiped
                    || this.lastDuration != duration
                    || this.lastNextRunDateTimePiped != nextRunDateTimePiped
                    || this.lastIsRunning != isRunning
                    || this.lastIsDue != isDue) {

                    this.historyChanged = "hasHistoryChanged";
                }

                this.lastLastStartDateTimePiped = lastStartDateTimePiped;
                this.lastLastFinishDateTimePiped = lastFinishedDateTimePiped;
                this.lastDuration = duration;
                this.lastNextRunDateTimePiped = nextRunDateTimePiped;
                this.lastIsRunning = isRunning;
                this.lastIsDue = isDue;

                this.itemManagedFieldsOnlyData = f;
                result.next(f);
                result.complete();
            }
        });

        return result;
    }

    public save = (): void => {

        this.appService.IsAppBusy = true;

        let isCreatingNew = this.itemData.ManagedFunctionId == -1;

        this.functionService.save(this.itemData).subscribe((f: any) => {

            if (f != null) {

                if (f instanceof HttpErrorResponse) {

                    this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

                } else {

                    if (this.itemData.ManagedFunctionTypeId == ManagedFunctionType.DataCopySqlToSqlFunction) {

                        this.functionService.saveDataCopySqlToSqlFunction(this.itemDataCopySqlToSqlFunctionData).subscribe((f: any) => {

                            if (f != null) {

                                this.loadData(true);
                                this.appService.IsAppBusy = false;

                                if (isCreatingNew) {

                                    this.location.replaceState('/function/' + this.itemData.functionId);
                                }
                            }
                        });

                    } else if (this.itemData.ManagedFunctionTypeId == ManagedFunctionType.DataPurgeSqlFunction) {

                        this.functionService.saveDataPurgeFunction(this.itemDataPurgeSqlFunctionData).subscribe((f: any) => {

                            if (f != null) {

                                this.loadData(true);
                                this.appService.IsAppBusy = false;

                                if (isCreatingNew) {

                                    this.location.replaceState('/function/' + this.itemData.functionId);
                                }
                            }
                        });

                    } else if (this.itemData.ManagedFunctionTypeId == ManagedFunctionType.DataCopySqlToDatalakeFunction) {

                        this.functionService.saveDataCopySqlToDatalakeFunction(this.itemDataCopySqlToDatalakeFunctionData).subscribe((f: any) => {

                            if (f != null) {

                                this.loadData(true);
                                this.appService.IsAppBusy = false;

                                if (isCreatingNew) {

                                    this.location.replaceState('/function/' + this.itemData.functionId);
                                }
                            }
                        });
                    }
                }
            }
        });
    }

    public delete = (): void => {

        //let proceedResponse = "PROCEED TO DELETE";

        //this.appService.ShowModalDialog(ModalDialogSeverity.Warning,
        //                                "Confirm Deletion",
        //                                "Are you sure you want to delete user " + this.itemData.FirstName + " " + this.itemData.LastName + ".  If you are 100% sure, click PROCEEED TO DELETE, otherwise CANCEL",
        //                                proceedResponse,
        //                                "CANCEL").subscribe((response) => {

        //    if (response == proceedResponse) {

        //        this.appService.IsAppBusy = true;

        //        this.userService.delete(this.itemData.Id).subscribe((r: any) => {

        //            if (r != '') {

        //                this.appService.IsAppBusy = false;

        //                if (r instanceof HttpErrorResponse) {

        //                    this.appService.ShowModalDialog(ModalDialogSeverity.Error, "API Error", "An error occurred calling API", "OK", "");

        //                } else {

        //                    this.appService.navigateTo('/usersearch');
        //                }
        //            }
        //        });
        //    }
        //});

        this.appService.showPendingFeatureMessage();
    }
}
