import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class LoaderComponent implements OnInit {

    public _overlayclassname = 'loader-overlay';

    public constructor(public appservice: AppService) {

        this.appservice.IsAppBusySubject.subscribe(isappbusy => {

            if (isappbusy != undefined) {

                if (isappbusy == true) {

                    this._overlayclassname = 'loader-overlay-loading';

                } else {

                    this._overlayclassname = 'loader-overlay';
                }
            }
        });
    }

    public ngOnInit() { }
}
