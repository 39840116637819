import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../../app.service';

@Injectable({
    providedIn: 'root'
})

export class ManagedFunctionService {

    private _baseUrl: string = '';

    public constructor(public appService: AppService, public http: HttpClient, @Inject('API_BASE_URL') baseurl: string) {

        this._baseUrl = baseurl;
    }

    public getManagedFunctionTypes = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/GetManagedFunctionTypes';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getDefaultSearchParameters = (): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/GetDefaultSearchParameters';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public search = (userSearchParameters: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/Search';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, userSearchParameters, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getById = (functionId: number): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/GetById/' + functionId.toString();
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getDataCopySqlToSqlFunctionById = (functionId: number): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/GetDataCopySqlToSqlFunctionById/' + functionId.toString();
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getDataCopySqlToDatalakeFunctionById = (functionId: number): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/GetDataCopySqlToDatalakeFunctionById/' + functionId.toString();
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getDataPurgeFunctionById = (functionId: number): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/GetDataPurgeFunctionById/' + functionId.toString();
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getRecentHistory = (functionId: number): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/GetManagedDataFunctionEventsByFunctionId/' + functionId.toString();
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public save = (f: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/Save';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, f, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public saveDataCopySqlToSqlFunction = (dcf: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/SaveDataCopySqlToSqlFunction';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, dcf, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public saveDataCopySqlToDatalakeFunction = (dcf: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/SaveDataCopySqlToDatalakeFunction';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, dcf, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public saveDataPurgeFunction = (dpf: any): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/SaveDataPurgeFunction';
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.post<any>(fullPath, dpf, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public getManagedFunctionManagedFields = (functionId: number): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/GetManagedFields/' + functionId;
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }

    public delete = (functionId: string): Observable<any> => {

        let result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
        const apipath: string = 'ManagedFunction/Delete/' + functionId;
        const fullPath: string = this._baseUrl + apipath;
        const options = this.appService.getHttpHeaderOptions();

        this.http.get<any>(fullPath, options).subscribe((response: any) => {

            result.next(response);
            result.complete();

        }, error => {

            result.next(error);
        });

        return result;
    }
}
