import { Inject, Injectable } from '@angular/core';
import { AppService } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationSettingsService {

  private _baseUrl: string = '';
  private _token: string  = '';

  public constructor(public appService: AppService, public http: HttpClient, @Inject('CREDITORPORTAL_API_BASE_URL') baseurl: string) {
      this._baseUrl = baseurl;

      const loggedUserSession = localStorage.getItem('userSession');
      if(loggedUserSession){
        const loggedUserToken = JSON.parse(loggedUserSession).AuthenticatedUser.Token;
        this._token = loggedUserToken;
      }
  }

  public getSmsPlatformTypeList = (): Observable<any> => {
    const apipath: string = "Creditor/sms-platform-type-list";
    const fullPath: string = this._baseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();

    return this.http.get<any>(fullPath, options);
  }

  public getEmailPlatformTypeList = (): Observable<any> => {
    const apipath: string = "Creditor/email-platform-type-list";
    const fullPath: string = this._baseUrl + apipath;
    const options = this.appService.getHttpHeaderOptions();

    return this.http.get<any>(fullPath, options);
  }

  public getNotificationSettings = (creditorId: string): Observable<any> => {
    const apipath: string = "Creditor/notification-settings";
    const fullPath: string = this._baseUrl + apipath;
    const options = this.appService.getHttpHeaderOptionsWithHeaderKey('X-Creditorid',creditorId);

    return this.http.get<any>(fullPath, options);
  }

  public putNotificationSettings = (sms: string, email: string, creditorId: string): Observable<any> => {
    const apipath: string = 'Creditor/notification-settings';
    const fullPath: string = this._baseUrl + apipath;
    const options = this.appService.getHttpHeaderOptionsWithHeaderKey('X-Creditorid',creditorId);
    const params: any = { sms, email};

    return this.http.put<any>(fullPath, params, options);
 }
}
